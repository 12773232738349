angular.module('BGEHeroes.controllers.Quiz2', [ ])

.controller('quiz2Ctrl', ['$scope', '$log', '$location', 'Gateway', '$rootScope', '$window',
	function($scope, $log, $location, Gateway, $rootScope, $window){
	
		$rootScope.loading = true;
		
		$scope.messageCall = function ( command, param ) {
			
			if (command == 'goto'){
				$rootScope.loading = true;		
				$location.url(param);
				$location.replace();
				if (!$rootScope.$$phase) $rootScope.$apply();
			}
			
			if (command == 'ready'){
				$rootScope.loading = false;
				if (!$rootScope.$$phase) $rootScope.$apply();
			}
			
			console.log('Command: ' + command + ' Message: ' + param );
			
		}
		
		$log.log ("Quiz2");
		//$rootScope.loading = false;
		
	}
]);
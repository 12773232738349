angular.module('BGEHeroes.services.LoaderSvcLepes5', [])

.service('loaderSvcLepes5', [ 'Gateway',  function (Gateway) {
   var manifest = [ 
       {src: "lepes5/hatter.png", id: "hatter1"},
       {src: "kozos/jobbra.png", id: "jobbra"},
       {src: "kozos/balra.png", id: "balra"},
       {src: "kozos/logo.png", id: "logo"},
       {src: "kozos/bar.png", id: "bar"},
       {src: "kozos/tovabb.png", id: "tovabb"},
       {src: "kozos/ujra.png", id: "ujra"},
	   {src: "kozos/ablak_nagy.png", id: "ablak_nagy"},
	   {src: "kozos/tipp.png", id: "tipp"},
	   
	   {src: "kozos/kihagy.png", id: "kihagy"},

	   {src: "lepes5/gombok.png", id: "gombok"},
	   {src: "lepes5/keret.png", id: "keret"},
	   {src: "lepes5/lift.png", id: "lift"},
	   {src: "lepes5/liftsmiley.png", id: "liftsmiley"},

			  
	   ];
   
   loader = new createjs.LoadQueue(false);
   
   this.getResult = function (asset) {
       return loader.getResult(asset);
   };
   this.getLoader = function () {
       return loader;
   };
   this.loadAssets = function () {  
		loader.loadManifest(manifest, true, Gateway.cordovaprefix + "/images/");
   };
}]);
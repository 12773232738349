angular.module('BGEHeroes.controllers.Bezaras', [])


.controller('bezarasCtrl', ['$scope', '$location', '$rootScope',

	function($scope, $location, $rootScope ) {

		console.log ("Bezaras ablak.");
		$rootScope.loading = false;
		
		//Functions
		$scope.closeapp = function(){

			
			
			if (typeof window.cordova === 'object') {
				navigator.app.exitApp();
			}
			else if (navigator.device) {
				navigator.device.exitApp();
			}else {
				window.close();
				open(location, '_self').close(); 
			}
			
		}

		//Functions
		$scope.home = function(){
			$location.path('/home');
		}

	}
]);
	

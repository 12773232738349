angular.module('BGEHeroes.controllers.Sugo', [])


.controller('sugoCtrl', ['$scope', '$rootScope',

	function($scope, $rootScope) {

		console.log ("Fut.");
		$rootScope.loading = false;

	}
	
]);
	

BGEHeroes = angular.module('BGEHeroes', [
  'ngRoute',
  'ngCookies',
  'ngSanitize',
	"com.2fdevs.videogular",
	"com.2fdevs.videogular.plugins.controls",
	"com.2fdevs.videogular.plugins.overlayplay",
	"com.2fdevs.videogular.plugins.poster",
	"com.2fdevs.videogular.plugins.buffering",
	
  'BGEHeroes.controllers.Sugo',
  'BGEHeroes.controllers.Bezaras',

  'BGEHeroes.services.Gateway',
  'BGEHeroes.services.Cordova',

   'BGEHeroes.controllers.Black',
  
  'BGEHeroes.controllers.Fomenu',
  'BGEHeroes.directives.Fomenu',
  'BGEHeroes.services.LoaderSvcFomenu',

  'BGEHeroes.controllers.Lepes1',
  'BGEHeroes.directives.Lepes1',
  'BGEHeroes.services.LoaderSvcLepes1',

  'BGEHeroes.controllers.Quiz1',
  'BGEHeroes.directives.Quiz1',
  'BGEHeroes.services.LoaderSvcQuiz1',
  
  'BGEHeroes.controllers.Lepes2',
  'BGEHeroes.directives.Lepes2',
  'BGEHeroes.services.LoaderSvcLepes2',

  'BGEHeroes.controllers.Quiz2',
  'BGEHeroes.directives.Quiz2',
  'BGEHeroes.services.LoaderSvcQuiz2',

  'BGEHeroes.controllers.Lepes3',
  'BGEHeroes.directives.Lepes3',
  'BGEHeroes.services.LoaderSvcLepes3',

  'BGEHeroes.controllers.Quiz3',
  'BGEHeroes.directives.Quiz3',
  'BGEHeroes.services.LoaderSvcQuiz3',
  
  'BGEHeroes.controllers.Lepes4',
  'BGEHeroes.directives.Lepes4',
  'BGEHeroes.services.LoaderSvcLepes4',

  'BGEHeroes.controllers.Quiz4',
  'BGEHeroes.directives.Quiz4',
  'BGEHeroes.services.LoaderSvcQuiz4',

  'BGEHeroes.controllers.Lepes5',
  'BGEHeroes.directives.Lepes5',
  'BGEHeroes.services.LoaderSvcLepes5',

  'BGEHeroes.controllers.Quiz5',
  'BGEHeroes.directives.Quiz5',
  'BGEHeroes.services.LoaderSvcQuiz5',

  'BGEHeroes.controllers.Lepes6',
  'BGEHeroes.directives.Lepes6',
  'BGEHeroes.services.LoaderSvcLepes6',

  'BGEHeroes.controllers.Quiz6',
  'BGEHeroes.directives.Quiz6',
  'BGEHeroes.services.LoaderSvcQuiz6',

  'BGEHeroes.controllers.Vege',
  'BGEHeroes.directives.Vege',
  'BGEHeroes.services.LoaderSvcVege'

])

.config(function($routeProvider) {
  $routeProvider.when('/', {templateUrl:'black.html', controller: 'blackCtrl', reloadOnSearch: false});
  $routeProvider.when('/fomenu', {templateUrl:'fomenu.html', controller: 'fomenuCtrl', reloadOnSearch: false});

  $routeProvider.when('/lepes1', {templateUrl:'lepes1.html', controller: 'lepes1Ctrl', reloadOnSearch: false});
  $routeProvider.when('/quizgame1', {templateUrl:'quiz1.html', controller: 'quiz1Ctrl', reloadOnSearch: false});

  $routeProvider.when('/lepes2', {templateUrl:'lepes2.html', controller: 'lepes2Ctrl', reloadOnSearch: false});
  $routeProvider.when('/quizgame2', {templateUrl:'quiz2.html', controller: 'quiz2Ctrl', reloadOnSearch: false});

  $routeProvider.when('/lepes3', {templateUrl:'lepes3.html', controller: 'lepes3Ctrl', reloadOnSearch: false});
  $routeProvider.when('/quizgame3', {templateUrl:'quiz3.html', controller: 'quiz3Ctrl', reloadOnSearch: false});

  $routeProvider.when('/lepes4', {templateUrl:'lepes4.html', controller: 'lepes4Ctrl', reloadOnSearch: false});
  $routeProvider.when('/quizgame4', {templateUrl:'quiz4.html', controller: 'quiz4Ctrl', reloadOnSearch: false});

  $routeProvider.when('/lepes5', {templateUrl:'lepes5.html', controller: 'lepes5Ctrl', reloadOnSearch: false});
  $routeProvider.when('/quizgame5', {templateUrl:'quiz5.html', controller: 'quiz5Ctrl', reloadOnSearch: false});
  
  $routeProvider.when('/lepes6', {templateUrl:'lepes6.html', controller: 'lepes6Ctrl', reloadOnSearch: false});
  $routeProvider.when('/quizgame6', {templateUrl:'quiz6.html', controller: 'quiz6Ctrl', reloadOnSearch: false});
  
  $routeProvider.when('/sugo', {templateUrl:'sugo.html',controller: 'sugoCtrl',  reloadOnSearch: false});
  $routeProvider.when('/bezaras', {templateUrl:'bezaras.html', controller: 'bezarasCtrl', reloadOnSearch: false});

  $routeProvider.when('/vege', {templateUrl:'vege.html', controller: 'vegeCtrl', reloadOnSearch: false});

  
})

.config(['$httpProvider', function($httpProvider) {
  $httpProvider.defaults.withCredentials = true;
  
  //$httpProvider.defaults.headers.common['Cache-Control'] = 'no-cache, no-store, must-revalidate';
  //$httpProvider.defaults.headers.common['Pragma'] = 'no-cache';
  //$httpProvider.defaults.headers.common['Expires'] = '0';    
  
}])

//.run(function($rootScope, $templateCache) {
//   $rootScope.$on('$viewContentLoaded', function() {
//      $templateCache.removeAll();
//   });
//})
;
angular.module('BGEHeroes.directives.Lepes4', [])

.directive('lepes4', ['loaderSvcLepes4', '$location', 'Gateway', '$rootScope', function (loaderSvcLepes4, $location, Gateway, $rootScope) {
       "use strict";
       return {
           restrict : 'EAC',
           replace : true,
           scope :{
			    onProxyMessage: '&'
           },
           template: "<canvas></canvas>",
           link: function (scope, element, attribute) {
			   
               var w, h, lepes;
			   
			   var hatter1, hatter2, bar, logo, tovabb, ujra, kihagy, tolt9, tolt4, le9, le4, edeny9, edeny4, szint9, szint4;
			   
			   var STAGE_WIDTH, STAGE_HEIGHT, STAGE_ASPECT_RATIO, scope;
			   
			   var visszaallitas;
			   var kattintva;
			   
			   var ablak_nagy, ablakszoveg, tippszoveg;
							
			   var palyaszam, pontszam;
			   
			   var indulopontszam;
			   var eletekszama;

			   var myrate = Gateway.framerate;
			   
			   var hozzavalok = {};
			   
			   var tipp;

			   var kihagypos = [1920-250, 1080-140];
			   var tipppos = [1920-250-216-50, 1080-140];
			   var ujrapos = [1920-250-216-150, 1080-140];
			   var tovabbpos = [1920-350, 1080-140];
			   
			   var uzenet;		   
			   var lock;
			   var megoldva;
			   
			   var idoszovegek = ["8:00", "8:15", "8:30", "8:45", "9:00", "9:15", "9:30", "9:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15",  "11:30",  "11:45",  "12:00", "12:15",  "12:30",  "12:45" ];
			   var idopos = [100, 100, 100, 0];
			   
			   var ugyintezoszovegek = ["1. ügyintéző",  "2. ügyintéző", "3. ügyintéző", "4. ügyintéző", "5. ügyintéző"];
			   var ugyintezopos = [100, 200, 0, 100];
			   
			   var idobeosztasszovegek = ["Időbeosztás"];
			   var idobeosztaspos = [50, 50, 0, 0];
			   
			   var szovegek = {
					tipp: "Megoldási javaslat: 9 feltölt, 9→4 áttölt, 4 kiürít, 9→4 áttölt, 4 kiürít, 9→4 áttölt, 9 feltölt, 9→4 áttölt",
					start: "Kattints a csapokra a feltöltéshez és kiürítéshez illetve az edényekre az áttöltéshez!",
					kesz: "Gratulálunk, a feladat megoldása sikeres volt!",
					nem: "Sajnos a feladat megoldása sikertelen volt!"
			   };
			   
			   //Színezés a hossz szerint
			   var szinek = ["blue", "blue", "blue", "red", "yellow", "white", "black", "green", "orange", "pink", "pink", "coral" ];
			   //Húzható feladatok
			   var feladatok = [2, 2, 3, 3, 3, 4, 4, 4, 5, 5, 6, 6, 7, 8, 8, 10];
			   var huzhatok = [];
			   
			   var kontenerek = [];
			   
			   lepes=0;
				
				szint9 = 0;
				szint4 = 0;
				var szint9s;
				var szint4s;
				
				var szint4o;
				var szint9o;
				
				var vege = 0;
				
               drawGame();
			   
               function drawGame() {
                   if (scope.stage) {
                       scope.stage.autoClear = true;
                       scope.stage.removeAllChildren();
                       scope.stage.update();
                   } else {
                       scope.stage = new createjs.Stage(element[0]);
                   }
                   w = scope.stage.canvas.width = STAGE_WIDTH = 1920;
                   h = scope.stage.canvas.height = STAGE_HEIGHT = 1080;
				   
					loaderSvcLepes4.getLoader().addEventListener("complete", handleComplete);
					loaderSvcLepes4.loadAssets();

					/////////////////////////////////////////
					STAGE_ASPECT_RATIO = STAGE_WIDTH / STAGE_HEIGHT;
					var containerWidth = window.innerWidth;
					var containerHeight = window.innerHeight;
					var brratio = containerWidth/containerHeight;
					if (STAGE_ASPECT_RATIO>brratio){
						//A Stage magasabb. Teljes szélesség, a magasságot kell korlátozni.
						scope.stage.canvas.width = containerWidth;
						scope.stage.canvas.height = containerWidth / ref;
						   
					}else{
						//A Stage szélesebb. Teljes magasság, a szélességet kell korlátozni.
						scope.stage.canvas.width = containerHeight * ref;
						scope.stage.canvas.height = containerHeight;
					}
					// scale stage to fit canvas
					var scalex = scope.stage.canvas.width / STAGE_WIDTH;
					var scaley = scope.stage.canvas.height / STAGE_HEIGHT;
					scope.stage.scaleX = scalex;
					scope.stage.scaleY = scaley;
					scope.stage.update();					
					/////////////////////////////////////////////
				   
               }


               function handleComplete() {
				   	
					lock = false;
					
					Gateway.pontkezdo = Gateway.pont;
					storagesave();
					
				   	//Gateway.pont += 100;
					//Gateway.palya += 1;					

					visszaallitas = 0;
				   
					hatter1 = addtostage ("hatter1", 0, 0, w, h);
					
					tippszoveg = addtext( w/2, h/2-100,  Gateway.kezdoszovegek[Gateway.currentstep] + "\n\n" + szovegek["tipp"], "70px RobotoRegular", "#000", 1500, "center", 1);				
					tippszoveg.alpha = 0;
					
					ablak_nagy = addsprite("ablak_nagy", 0, 0, w, h, true );
					ablakszoveg = addtext( w/2, h/2-100, Gateway.kezdoszovegek[Gateway.currentstep], "70px RobotoRegular", "#000", 1500, "center", 1);				
					
					scope.stage.update();				
					ablakszoveg.y = h/2-ablakszoveg.getBounds().height/2;
					scope.stage.update();
					
					uzenet = addtext( 350, h-100, szovegek["start"], "40px RobotoRegular", "#000", 1050, "left", 1);
					uzenet.alpha = 0;
					
					logo = addsprite ("logo", 0, 50);
					logo.addEventListener("mousedown", fomenube);
					
					bar = addsprite ("bar", w-50-577, 50);

					createjs.Ticker.timingMode = createjs.Ticker.TIMEOUT;
					createjs.Ticker.framerate = myrate;
					createjs.Ticker.addEventListener("tick", tick);
				    createjs.Ticker.addEventListener("tick", createjs.Tween);
				   
					scope.onProxyMessage( {command: 'ready', param: ''} );
					
					updatepalyaszam();
					updatepontszam();

					scope.stage.update();
										
				   	createjs.Touch.enable(scope.stage);
					scope.stage.enableMouseOver(10);
					scope.stage.mouseMoveOutside = true;
					
					scope.stage.addEventListener("stagemousedown", handleComplete2);
					
			   }


               function handleComplete2() {
				   
					kihagy = addsprite ( "kihagy", kihagypos[0], kihagypos[1] );
					kihagy.addEventListener("mousedown", kattintas);
					kihagy.alpha = 1;
				   
				    if (Gateway.isios){
					   Gateway.audio.powerup.play();
				   }else{
						createjs.Sound.play("powerup");
				   }
				   				   
					scope.stage.removeEventListener("stagemousedown", handleComplete2);
				   	ablak_nagy.alpha=0;
					ablakszoveg.alpha=0;
					
					//Gomb rajzolása
					ujra = addsprite ( "ujra", ujrapos[0], ujrapos[1] );
					ujra.addEventListener("mousedown", kattintas);
					ujra.alpha = 0;

					tovabb = addsprite ( "tovabb", tovabbpos[0], tovabbpos[1] );
					tovabb.addEventListener("mousedown", kattintas);
					tovabb.alpha = 0;


					tipp = addsprite ( "tipp", tipppos[0], tipppos[1] );
					tipp.addEventListener("mousedown", kattintas);
					tipp.alpha = 1;

					szovegkijelzes ("start");
					scope.stage.update();
					
					//var myGraphics = new createjs.Graphics();
					//myGraphics.beginStroke("red").beginFill("blue").drawRect(0, 0, 100, 50);
					//stage.addChild(myGraphics);
					
					//var uzenet = new createjs.Text("abc", "bold 16px Courier", "#fff");
					
					//var container = new createjs.Container();
					//stage.addChild(container);
					
					//container.addChild(myGraphics, uzenet);
					//container.x = 100;
					//container.y = 100;

					edeny9 = addsprite ( "edenybal", w/3-200, h/2-180 );
					edeny9.addEventListener("mousedown", edeny9f);

					edeny4 = addsprite ( "edenyjobb", 2*w/3-280, h/2-60 );
					edeny4.addEventListener("mousedown", edeny4f);

					tolt9 = addsprite ( "csap", w/3-280, h/2-380 );
					tolt9.addEventListener("mousedown", tolt9f);

					tolt4 = addsprite ( "csap", 2*w/3-370, h/2-275 );
					tolt4.addEventListener("mousedown", tolt4f);

					le9 = addsprite ( "csap", w/3-30, h/2+210 );
					le9.addEventListener("mousedown", le9f);

					le4 = addsprite ( "csap", 2*w/3-190, h/2+210 );
					le4.addEventListener("mousedown", le4f);

					szintkijelzo ();
					
			   }
			   
			   function kiertekel(){
				   
			   }
			   
			   function szintkijelzo (){
					if (szint9s) scope.stage.removeChild(szint9s);
					szint9s = addtext( w/3, h/2-250, szint9 , "35px RobotoBold", "#000", 300, "center", true);

					if (szint4s) scope.stage.removeChild(szint4s);
					szint4s = addtext( 2*w/3-150, h/2-120, szint4 , "35px RobotoBold", "#000", 300, "center", true);

					if (szint9o) scope.stage.removeChild(szint9o);
					if (szint4o) scope.stage.removeChild(szint4o);
					if (szint9){
						
						var balalso9x = w/3-175;
						var balalso9y =  h/2-240 + 446;
						var szint9szelesseg = 297;
						var szint9lepes=40;
						
						var graphics = new createjs.Graphics().beginFill("#0a3ca3").drawRect( balalso9x, balalso9y-szint9*szint9lepes, szint9szelesseg, szint9*szint9lepes);
						szint9o = new createjs.Shape(graphics);
						scope.stage.addChild(szint9o);
					}

					if (szint4){


						var balalso4x = 2*w/3-258;
						var balalso4y =  h/2-40 + 247;
						var szint4szelesseg = 223;
						var szint4lepes=43;
						
						var graphics = new createjs.Graphics().beginFill("#0a3ca3").drawRect( balalso4x, balalso4y-szint4*szint4lepes, szint4szelesseg, szint4*szint4lepes);
						szint4o = new createjs.Shape(graphics);
						scope.stage.addChild(szint4o);
					}
					
					if (szint9 == 6){
						vege = 1;
						tipp.alpha = 0;
						kihagy.alpha=0;
						tovabb.alpha = 1;
						ujra.alpha = 1;
						szovegkijelzes("kesz");
					}
					
			   }

			   function edeny9f(){
					
					if (vege==1) return;
					
					var lehet4be = 4 - szint4;
					
					if (szint9 >= lehet4be){
						szint9 -= lehet4be;
						szint4 = 4;
					}else{
						szint4 += szint9;
						szint9 = 0;
					}
					
					szintkijelzo ();
					
			   }

			   function edeny4f(){
			   
					if (vege==1) return;
			   
			   		var lehet9be = 9 - szint9;
					
					if (szint4 >= lehet9be){
						szint4 -= lehet9be;
						szint9 = 9;
					}else{
						szint9 += szint4;
						szint4 = 0;
					}
					
					szintkijelzo ();
			   
			   }			   
			   
			   function tolt9f(){
				   
				   if (vege==1) return;
				   
				    console.log("Feltölt 9");
					szint9 = 9;
					szintkijelzo();
			   }
			   

			   function tolt4f(){
				   
				   if (vege==1) return;
				   
				    console.log("Feltölt 4");
					szint4 = 4;
					szintkijelzo();
			   }

			   function le9f(){
				   
				   if (vege==1) return;
				   
  				    console.log("leereszt 9");
			   		szint9 = 0;
					szintkijelzo();
			   }
			   

			   function le4f(){
				   
				   if (vege==1) return;
				   
  				    console.log("leereszt 4");
			   		szint4 = 0;
					szintkijelzo();			   
			   }
			   
			   function sugotmutat (){
				   
				    scope.stage.setChildIndex( ablak_nagy, scope.stage.getNumChildren()-1);
				    ablak_nagy.alpha=1;
				   
				    scope.stage.setChildIndex( tippszoveg, scope.stage.getNumChildren()-1);
				   
				   
					tippszoveg.y = h/2-tippszoveg.getBounds().height/2;
					scope.stage.update();
					
					tippszoveg.alpha=1;
				    scope.stage.addEventListener("stagemousedown", sugokikapcs);
				   
			   }
			   
			   function sugokikapcs (){
				    scope.stage.removeEventListener("stagemousedown", sugokikapcs);
				   	ablak_nagy.alpha=0;
					tippszoveg.alpha=0;
					
					szovegkijelzes ("start");
					
			   }
			   
			   function addtostage (id, x, y, w, h){
					var shape;
					shape = new createjs.Shape();
					shape.graphics.beginBitmapFill(loaderSvcLepes4.getResult(id)).drawRect(x, y, w, h);
					scope.stage.addChild(shape);
					return shape;
			   }
			   
			   function addsprite (id, x, y){
				   var bitmap;
				   bitmap = new createjs.Bitmap(loaderSvcLepes4.getResult(id));
				   bitmap.x = x;
				   bitmap.y = y;
				   scope.stage.addChild(bitmap);
				   return bitmap;
			   }
			   
			   	function storagesave(){
				   
				   	var storage = window.localStorage;

					storage.setItem("currentstep", Gateway.currentstep );
					storage.setItem("palya", Gateway.palya);
					storage.setItem("pont", Gateway.pont);
					storage.setItem("pontkezdo", Gateway.pontkezdo );
				   
			   }
			   
			   function fomenube(){
				   
				   	$rootScope.loading = true;					
			   		createjs.Ticker.reset();
					scope.stage.removeAllChildren();
					scope.stage.removeAllEventListeners();
					loaderSvcLepes4.getLoader().removeEventListener("complete", handleComplete);
				   
				   scope.onProxyMessage( {command: 'goto', param: "/fomenu"} );
			   }
			   
			   
			   function szovegkijelzes (id){
				 
				    visszaallitas = Gateway.szovegvisszaallas;
					uzenet.text = szovegek[id];
				    uzenet.alpha = 1;
			   }
			   

			   function addtext (xpos, ypos, text, font, mycolor, mywidth, myalign, myalpha ){
				   var myshape;

					var offsetx = 0;
					var offsety = 0;
				
				   if (xpos<0) xpos=w+xpos;
				   if (ypos<0) ypos=h+ypos;
				   
				   myshape = new createjs.Text(text, font, mycolor);
				   myshape.x = xpos + offsetx;
				   myshape.y = ypos + offsety ;
				   myshape.name = text;
				   
				   scope.stage.addChild(myshape);

				   myshape.lineWidth=mywidth;
				   myshape.textAlign=myalign;
				   myshape.alpha=myalpha;
				   
				   return myshape;
			   }
			   
			    function addsprite (id, x, y){
				   var bitmap;
				   bitmap = new createjs.Bitmap(loaderSvcLepes4.getResult(id));
				   bitmap.name = id;
				   bitmap.x = x;
				   bitmap.y = y;
				   scope.stage.addChild(bitmap);
				   return bitmap;
			   }			   
			   
				function updatepalyaszam (){
					if (palyaszam) scope.stage.removeChild(palyaszam);
					palyaszam = addtext( w-50-420, 75+Gateway.fontdiff, Gateway.palya + "." , "35px RobotoBold", "#FFFFFF", 100, "left", true);
				}

				function updatepontszam (){
					if (pontszam) scope.stage.removeChild(pontszam);
					pontszam = addtext( w-50-110, 75+Gateway.fontdiff, Gateway.pont , "35px RobotoBold", "#FFFFFF", 100, "left", true);
				}
				

			   
			   function tick(event) {

					if (!lock){
						if (visszaallitas>0){
							visszaallitas--;
						}else{
							uzenet.alpha = 0;
						}
					}
					
                   scope.stage.update(event);
               }

				function ujrarajzol(){
					
					console.log ("Újrarajzolás");
					
						vege = 0;
						szint4=0;
						szint9=0;
						tipp.alpha = 1;
						kihagy.alpha=1;
						tovabb.alpha = 0;
						ujra.alpha = 0;
						szovegkijelzes("start");
						szintkijelzo();		
					
				
				}
				
				function reset(){
					
					ujrarajzol();
					
				}
				

			   function kattintas (event){
				   

					var nev = event.target.name;

					if (nev == "ujra"){
						reset();
						return;
					}

					if (nev == "kihagy"){
						//Gateway.pont += 100;
						Gateway.palya += 1;					
						storagesave();
						
						if (Gateway.isios){
							Gateway.audio.drainmagic.play();
						}else{
							createjs.Sound.play("drainmagic");
						}
						closeandjump();
						return;
					}

					if (nev == "tovabb"){
						
						Gateway.pont += 100;
						Gateway.palya += 1;					
						storagesave();
						
						if (Gateway.isios){
							Gateway.audio.drainmagic.play();
						}else{
							createjs.Sound.play("drainmagic");
						}
						closeandjump();
						return;
					}

					if (nev == "tipp"){
						sugotmutat();
						return;
					}
					
					if (lock)return
					
				   if (Gateway.isios){
					   Gateway.audio.swish.play();
				   }else{
					   createjs.Sound.play("swish");
				   }


					
			   }
			   
			   function closeandjump(step){
					
					$rootScope.loading = true;					
			   		createjs.Ticker.reset();
					scope.stage.removeAllChildren();
					scope.stage.removeAllEventListeners();
					loaderSvcLepes4.getLoader().removeEventListener("complete", handleComplete);
					
					var ide;
					
					if (step === undefined){
						ide=Gateway.steps[++Gateway.currentstep];
						if (ide === undefined){
							Gateway.currentstep = 0;
							ide=Gateway.steps[Gateway.currentstep];
						}
					}else{
						Gateway.currentstep = step;
						ide=Gateway.steps[Gateway.currentstep];
					}
					
					scope.onProxyMessage( {command: 'goto', param: ide} );
					
			   }
			   
           }
       }
   }]);
angular.module('BGEHeroes.directives.Fomenu', [])

.directive('fomenu', ['loaderSvcFomenu', '$location', 'Gateway', '$rootScope', '$route' , function (loaderSvcFomenu, $location, Gateway, $rootScope, $route) {
       "use strict";
       return {
           restrict : 'EAC',
           replace : true,
           scope :{
			    onProxyMessage: '&'
           },
           template: "<canvas></canvas>",
           link: function (scope, element, attribute) {
			   
               var w, h, sky, STAGE_WIDTH, STAGE_HEIGHT, STAGE_ASPECT_RATIO, scope, belepes;
			   
			   var lepes1, lepes2, lepes3, feny, sugo, leiras, ablak_nagy, sugoablak, ablakszoveg, kilepes, folytatas, folytataspalya, folytataspont;
			   
               drawGame();
               function drawGame() {
                   //drawing the game canvas from scratch here
                   //In future we can pass stages as param and load indexes from arrays of background elements etc
                   if (scope.stage) {
                       scope.stage.autoClear = true;
                       scope.stage.removeAllChildren();
                       scope.stage.update();
                   } else {
                       scope.stage = new createjs.Stage(element[0]);
                   }
                   w = scope.stage.canvas.width = STAGE_WIDTH = 1920;
                   h = scope.stage.canvas.height = STAGE_HEIGHT = 1080;
				   
					loaderSvcFomenu.getLoader().addEventListener("complete", handleComplete);
					loaderSvcFomenu.loadAssets();

					/////////////////////////////////////////
					STAGE_ASPECT_RATIO = STAGE_WIDTH / STAGE_HEIGHT;
					var containerWidth = window.innerWidth;
					var containerHeight = window.innerHeight;
					var brratio = containerWidth/containerHeight;
					if (STAGE_ASPECT_RATIO>brratio){
						//A Stage magasabb. Teljes szélesség, a magasságot kell korlátozni.
						scope.stage.canvas.width = containerWidth;
						scope.stage.canvas.height = containerWidth / ref;
						   
					}else{
						//A Stage szélesebb. Teljes magasság, a szélességet kell korlátozni.
						scope.stage.canvas.width = containerHeight * ref;
						scope.stage.canvas.height = containerHeight;
					}
					// scale stage to fit canvas
					var scalex = scope.stage.canvas.width / STAGE_WIDTH;
					var scaley = scope.stage.canvas.height / STAGE_HEIGHT;
					scope.stage.scaleX = scalex;
					scope.stage.scaleY = scaley;
					scope.stage.update();					
					/////////////////////////////////////////////
					
					/* orig
					STAGE_ASPECT_RATIO = STAGE_WIDTH / STAGE_HEIGHT;
				   
				       var containerWidth = window.innerWidth;
						var containerHeight = window.innerHeight;

						scope.stage.canvas.width = containerWidth;
						scope.stage.canvas.height = containerHeight;
						
						// scale stage to fit canvas
						var scalex = scope.stage.canvas.width / STAGE_WIDTH;
						var scaley = scope.stage.canvas.height / STAGE_HEIGHT;
						
						scope.stage.scaleX = scalex;
						scope.stage.scaleY = scaley;
						
						scope.stage.update();
					*/	
               }
			   
			   
			   function addsprite (id, x, y){
				   var bitmap;
				   bitmap = new createjs.Bitmap(loaderSvcFomenu.getResult(id));
				   bitmap.x = x;
				   bitmap.y = y;
				   scope.stage.addChild(bitmap);
				   return bitmap;
			   }
			   
               function handleComplete() {

			   		createjs.Touch.enable(scope.stage);
					scope.stage.enableMouseOver(Gateway.framerate);
					scope.stage.mouseMoveOutside = true;
			   
                   lepes1 = new createjs.Shape();
				   lepes1.graphics.beginBitmapFill(loaderSvcFomenu.getResult("lepes1")).drawRect(0, 0, w, h);
				   lepes1.alpha = 0;

                   lepes2 = new createjs.Shape();
				   lepes2.graphics.beginBitmapFill(loaderSvcFomenu.getResult("lepes2")).drawRect(0, 0, w, h);
				   lepes2.alpha = 0;

                   lepes3 = new createjs.Shape();
				   lepes3.graphics.beginBitmapFill(loaderSvcFomenu.getResult("lepes3")).drawRect(0, 0, w, h);
				   lepes3.alpha = 0;
				   
                    scope.stage.addChild(lepes1, lepes2, lepes3);

					belepes = addsprite ("belepes", w/2-100, h-200);
					belepes.addEventListener("mousedown", handleJumpStart);
					belepes.alpha=0;
					
				   	sugo = addsprite ("sugo", 3*w/4-100, h-200);
					sugo.addEventListener("mousedown", sugofel);
					sugo.alpha=0;
					
				   	leiras = addsprite ("leiras", w/4-100, h-200);
					leiras.addEventListener("mousedown", leirasfel);
					leiras.alpha=0;
					
				   	kilepes = addsprite ("kilepes", 4*w/5, +50);
					kilepes.addEventListener("mousedown", kilepesf);
					kilepes.alpha=0;

				   	folytatas = addsprite ("folytatas", 4*w/5, +250);
					folytatas.addEventListener("mousedown", folytatasf);
					folytatas.alpha=0;					
					
                   //scope.stage.addEventListener("stagemousedown", handleJumpStart);
		
					
					ablak_nagy = addsprite("ablak_nagy", 0, 0, w, h, true );
					ablak_nagy.addEventListener("mousedown", stage3up);
					
					ablakszoveg = addtext( w/2, h/2-100, Gateway.kezdoszovegek[1], "80px RobotoRegular", "#000", true);
					
					ablak_nagy.alpha=0;
					ablakszoveg.alpha =0;
				    ablakszoveg.lineWidth=1500;
				    ablakszoveg.textAlign="center";
					scope.stage.update();
					ablakszoveg.y = h/2-ablakszoveg.getBounds().height/2;
				
					sugoablak = addsprite("sugoablak", 0, 0, w, h, true );
					sugoablak.addEventListener("mousedown", stage3up);
					sugoablak.alpha =0;
					
					scope.stage.update();
					
					createjs.Ticker.timingMode = createjs.Ticker.TIMEOUT;
				   	createjs.Ticker.framerate = Gateway.framerate;
                   createjs.Ticker.addEventListener("tick", tick);
				   createjs.Ticker.addEventListener("tick", createjs.Tween);

   				   scope.onProxyMessage( {command: 'ready', param: ''} );
				   
				   if ( Gateway.currentstep != 1){
					   stage3up();
					   storagesave();
				   }else{
						// Ideiglenes
						createjs.Tween.get(lepes1).to({alpha:0.01}, 3000).call(stage0).wait(50);
						storageload();
					    //stage3up();
				   }

				   
			   }
			   
			   function storageload(){

				   var storage = window.localStorage;

				   var value = storage.getItem("currentstep");
				   if (value != null) Gateway.currentstep = parseInt(value);

				   var value = storage.getItem("palya");
				   if (value != null) Gateway.palya = parseInt (value);
				   
				   var value = storage.getItem("pont");
				   if (value != null) Gateway.pont = parseInt (value);

				   var value = storage.getItem("pontkezdo");
				   if (value != null) Gateway.pontkezdo = parseInt(value);
				   
			   }
			   
			   function storagesave(){
				   
				   	var storage = window.localStorage;

					storage.setItem("currentstep", Gateway.currentstep );
					storage.setItem("palya", Gateway.palya);
					storage.setItem("pont", Gateway.pont);
					storage.setItem("pontkezdo", Gateway.pontkezdo );
				   
			   }
			   
			    function kilepesf(){
						navigator.app.exitApp();
				}
			   
			   	function folytatasf(){
				   
				   	$rootScope.loading = true;					
			   		createjs.Ticker.reset();
					scope.stage.removeAllChildren();
					scope.stage.removeAllEventListeners();
					loaderSvcFomenu.getLoader().removeEventListener("complete", handleComplete);
				   
				   //Gateway.palya--;
				   Gateway.pont = Gateway.pontkezdo;
				   
				   scope.onProxyMessage( {command: 'goto', param: Gateway.steps[Gateway.currentstep] } );
			   }
			   
			   function addtext (xpos, ypos, text, font, color, add){
				   var myshape;
			   
				   myshape = new createjs.Text(text, font, color);
				   myshape.x = xpos;
				   myshape.y = ypos;
				   myshape.name = text;
				   
				   if (add) scope.stage.addChild(myshape);
				   
				   return myshape;
			   }
			   
			   function stage0(){
				   
				   if (Gateway.isios){
					   Gateway.audio.intro.play();
				   }else{
						createjs.Sound.play("intro");
				   }
					//feny = addsprite ("feny", w/2-100, h-520);
					//feny = addsprite ("feny", w/2-1100, h-520);
					//feny.alpha = 0;
					
   				    //createjs.Tween.get(feny).to({alpha:1, x:850}, 2000).wait(50);
					createjs.Tween.get(lepes1).to({alpha:0.8}, 2000).call(stage1up).wait(50);
			   
			   }
			   
			   function stage1up (){
	   				   createjs.Tween.get(lepes1).to({alpha:0}, 2000).call(stage1down).wait(50);
   				       //createjs.Tween.get(feny).to({alpha:0, x:-100}, 100);
			   }
			   
			   function stage1down (){			   
				   createjs.Tween.get(lepes2).to({alpha:1}, 2000).call(stage2up).wait(50);
			   }

			   function stage2up (){
	   				   createjs.Tween.get(lepes2).to({alpha:0}, 2000).call(stage2down).wait(50);
			   }

			   function stage2down (){			   
				   createjs.Tween.get(lepes3).to({alpha:1}, 2000).call(stage3up).wait(50);
				   //feny.x = -110;
				   //feny.y=h-800;
				   //createjs.Tween.get(feny).to({alpha:1, x:w/2-122, y:feny.y+25}, 2000).wait(50);
			   }

			   function mutatfolytatas(){
				   if (Gateway.currentstep != 1 ){
					   folytatas.alpha=1;
				   }else{
					   folytatas.alpha=0;
				   }
			   }

			   function elrejtfolytatas(){
				   folytatas.alpha=0;
			   }
			   
			   function stage3up (){
				   
				   lepes3.alpha = 1;
				   
				   //if (feny) feny.alpha=0;
				   leiras.alpha=1;
				   sugo.alpha=1;
				   belepes.alpha=1;
				   sugoablak.alpha =0;
				   ablak_nagy.alpha=0;
				   ablakszoveg.alpha =0;
				   
				   kilepes.alpha=1;
				   mutatfolytatas();
				   
			   }

			   function sugofel(){
				   if (Gateway.isios){
					   Gateway.audio.swish.play();
				   }else{
					   createjs.Sound.play("swish");
				   }
				   leiras.alpha=0;
				   sugo.alpha=0;
				   belepes.alpha=0;
				   sugoablak.alpha =1;
				   ablak_nagy.alpha=0;
				   
				   kilepes.alpha=0;
				   elrejtfolytatas();
				   
			   }
			   
			   function leirasfel(){
				   if (Gateway.isios){
					   Gateway.audio.swish.play();
				   }else{
					   createjs.Sound.play("swish");
				   }
				   leiras.alpha=0;
				   sugo.alpha=0;
				   belepes.alpha=0;
				   sugoablak.alpha =0;
				   ablak_nagy.alpha=1;
				   ablakszoveg.alpha =1;
				   
				   kilepes.alpha=0;
				   elrejtfolytatas();

				   
			   }
			   
			   function tick(event) {

                   scope.stage.update(event);
               }

               function handleJumpStart() {
				   
				   //Belépés volt, nullázunk.
				   Gateway.pont=0;
				   Gateway.pontkezdo=0;
				   Gateway.palya=0;
				   Gateway.currentstep = 1;			   
				   
				    if (Gateway.isios){
					   Gateway.audio.powerup.play();
				   }else{
					   createjs.Sound.play("powerup");
				   }
					closeandjump();
			   }
			   
			   	function closeandjump(step){
					
					$rootScope.loading = true;
					
			   		createjs.Ticker.reset();
					scope.stage.removeAllChildren();
					scope.stage.removeAllEventListeners();
					loaderSvcFomenu.getLoader().removeEventListener("complete", handleComplete);
					
					var ide;
					
					if (step === undefined){
						ide=Gateway.steps[++Gateway.currentstep];
						if (ide === undefined){
							Gateway.currentstep = 0;
							ide=Gateway.steps[Gateway.currentstep];
						}
					}else{
						Gateway.currentstep = step;
						ide=Gateway.steps[Gateway.currentstep];
					}
					
					scope.onProxyMessage( {command: 'goto', param: ide} );
				   
               }

			   
           }
       }
   }]);
angular.module('BGEHeroes.services.LoaderSvcLepes3', [])

.service('loaderSvcLepes3', [ 'Gateway',  function (Gateway) {
   var manifest = [ 
       {src: "lepes3/hatter.png", id: "hatter1"},
       {src: "kozos/jobbra.png", id: "jobbra"},
       {src: "kozos/balra.png", id: "balra"},
       {src: "kozos/logo.png", id: "logo"},
       {src: "kozos/bar.png", id: "bar"},
       {src: "kozos/tovabb.png", id: "tovabb"},
       {src: "kozos/ujra.png", id: "ujra"},
	   {src: "kozos/ablak_nagy.png", id: "ablak_nagy"},
	   {src: "kozos/tipp.png", id: "tipp"},
	   
	   {src: "kozos/kihagy.png", id: "kihagy"},
	   
       {src: "lepes3/kor.png", id: "kor"},
       {src: "lepes3/1.png", id: "1"},
       {src: "lepes3/2.png", id: "2"},
       {src: "lepes3/3.png", id: "3"},
       {src: "lepes3/4.png", id: "4"},
       {src: "lepes3/5.png", id: "5"},
       {src: "lepes3/6.png", id: "6"},
       {src: "lepes3/7.png", id: "7"},
       {src: "lepes3/8.png", id: "8"},
       {src: "lepes3/9.png", id: "9"},
       {src: "lepes3/10.png", id: "10"},
       {src: "lepes3/11.png", id: "11"}
			  
	   ];
   
   loader = new createjs.LoadQueue(false);
   
   this.getResult = function (asset) {
       return loader.getResult(asset);
   };
   this.getLoader = function () {
       return loader;
   };
   this.loadAssets = function () {  
		loader.loadManifest(manifest, true, Gateway.cordovaprefix + "/images/");
   };
}]);
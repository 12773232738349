angular.module('BGEHeroes.services.LoaderSvcFomenu', [])

.service('loaderSvcFomenu', [ 'Gateway',  function (Gateway) {
   var manifest = [ 
	   {src: "kozos/belepes.png", id: "belepes"},
	   {src: "kozos/sugo.png", id: "sugo"},
	   {src: "kozos/leiras.png", id: "leiras"},
	   {src: "kozos/sugoablak.png", id: "sugoablak"},
	   {src: "kozos/ablak_nagy.png", id: "ablak_nagy"},
	   {src: "kozos/kilepes.png", id: "kilepes"},
	   {src: "kozos/folytatas.png", id: "folytatas"},
	   
       {src: "fomenu/anim_eleje_bge.png", id: "lepes1"},
       {src: "fomenu/anim_eleje_bge_feny.png", id: "feny"},
       {src: "fomenu/anim_eleje_szlogo.png", id: "lepes2"},
       {src: "kozos/bg.png", id: "lepes3"},
   ];
   
   loader = new createjs.LoadQueue(false);
   
   this.getResult = function (asset) {
       return loader.getResult(asset);
   };
   this.getLoader = function () {
       return loader;
   };
   this.loadAssets = function () {  
		loader.loadManifest(manifest, true, Gateway.cordovaprefix + "/images/");
   };
}]);
angular.module('BGEHeroes.directives.Lepes2', [])

.directive('lepes2', ['loaderSvcLepes2', '$location', 'Gateway', '$rootScope', function (loaderSvcLepes2, $location, Gateway, $rootScope) {
       "use strict";
       return {
           restrict : 'EAC',
           replace : true,
           scope :{
			    onProxyMessage: '&'
           },
           template: "<canvas></canvas>",
           link: function (scope, element, attribute) {
			   
               var w, h, lepes;
			   
			   var hatter1, hatter2, bar, logo, tovabb, ujra, kihagy;
			   
			   var STAGE_WIDTH, STAGE_HEIGHT, STAGE_ASPECT_RATIO, scope;
			   
			   var visszaallitas;
			   var kattintva;
			   
			   var ablak_nagy, ablakszoveg, tippszoveg;
							
			   var palyaszam, pontszam;
			   
			   var indulopontszam;
			   var eletekszama;

			   var myrate = Gateway.framerate;
			   
			   var hozzavalok = {};
			   
			   var tipp;

			   var kihagypos = [1920-250, 1080-160];
			   var tipppos = [1920-250-250-280, 1080-160];
			   var ujrapos = [1920-250-240, 1080-160];
			   var tovabbpos = [1920-250, 1080-160];
			   
			   var uzenet;		   
			   var lock;
			   var megoldva;
			   
			   var idoszovegek = ["8:00", "8:15", "8:30", "8:45", "9:00", "9:15", "9:30", "9:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15",  "11:30",  "11:45",  "12:00" ];
			   //var idopos = [100, 100, 100, 0];
			   
			   var ugyintezoszovegek = ["1. ügyintéző",  "2. ügyintéző", "3. ügyintéző", "4. ügyintéző", "5. ügyintéző"];
			   //var ugyintezopos = [100, 200, 0, 100];
			   
			   //var idobeosztasszovegek = ["Időbeosztás"];
			   //var idobeosztaspos = [50, 50, 0, 0];
			   
			   var szovegek = {
					tipp: "\n\n1. megoldás: Az adott ügyintéző idejét használjuk ki minél jobban: az elsőt feltöltjük a lehető leghosszabb ügyekkel, majd a másodikat és így tovább. Ez rögtön eredményre vezet!\n\n2. megoldás: A mohó algoritmus gondolatvilágával: a legkorábbtól szabad ügyintéző megkapja a hátralévő leghosszabb ügyet (LPT algoritmus).",
					start: "Húzd az ügyintéző sorába a következő feladatot!",
					kesz: "Gratulálunk, a feladat megoldása sikeres volt!",
					nem: "Sajnos a feladat megoldása sikertelen volt!"
			   };
			   
			   //Színezés a hossz szerint
			   var szinek = ["blue", "blue", "red", "red", "red", "yellow", "yellow", "yellow", "green", "green", "orange", "orange", "pink", "white", "white", "coral" ];
			   //Húzható feladatok
			   var feladatok = [2, 2, 3, 3, 3, 4, 4, 4, 5, 5, 6, 6, 7, 8, 8, 10];
			   
			   
			   var huzhatok = [];
			   
			   var telitettseg = [0,0,0,0,0];
			   var telitettsegmax = 16;
			   
			   var vonalak = [
			   
						//Elejex, eleje y , végex, végey, +x, +y, db
						[200, 200, 1600, 0, 0, 75, 7],
						[400, 200, 0, 450, 75, 0, 17]
			   
			   ]
			   
			   var kontenerek = [];
			   
			   lepes=0;
									
               drawGame();
			   
               function drawGame() {
                   if (scope.stage) {
                       scope.stage.autoClear = true;
                       scope.stage.removeAllChildren();
                       scope.stage.update();
                   } else {
                       scope.stage = new createjs.Stage(element[0]);
                   }
                   w = scope.stage.canvas.width = STAGE_WIDTH = 1920;
                   h = scope.stage.canvas.height = STAGE_HEIGHT = 1080;
				   
					loaderSvcLepes2.getLoader().addEventListener("complete", handleComplete);
					loaderSvcLepes2.loadAssets();

					/////////////////////////////////////////
					STAGE_ASPECT_RATIO = STAGE_WIDTH / STAGE_HEIGHT;
					var containerWidth = window.innerWidth;
					var containerHeight = window.innerHeight;
					var brratio = containerWidth/containerHeight;
					if (STAGE_ASPECT_RATIO>brratio){
						//A Stage magasabb. Teljes szélesség, a magasságot kell korlátozni.
						scope.stage.canvas.width = containerWidth;
						scope.stage.canvas.height = containerWidth / ref;
						   
					}else{
						//A Stage szélesebb. Teljes magasság, a szélességet kell korlátozni.
						scope.stage.canvas.width = containerHeight * ref;
						scope.stage.canvas.height = containerHeight;
					}
					// scale stage to fit canvas
					var scalex = scope.stage.canvas.width / STAGE_WIDTH;
					var scaley = scope.stage.canvas.height / STAGE_HEIGHT;
					scope.stage.scaleX = scalex;
					scope.stage.scaleY = scaley;
					scope.stage.update();					
					/////////////////////////////////////////////
				   
               }


               function handleComplete() {
				   	
					lock = false;
					
					Gateway.pontkezdo = Gateway.pont;
					storagesave();
					
				   	//Gateway.pont += 100;
					//Gateway.palya += 1;					

					visszaallitas = 0;
				   
					hatter1 = addtostage ("hatter1", 0, 0, w, h);
					
					tippszoveg = addtext( w/2, h/2-100, Gateway.kezdoszovegek[Gateway.currentstep] + szovegek["tipp"], "50px RobotoRegular", "#000", 1500, "center", 1);				
					tippszoveg.alpha = 0;
					
					ablak_nagy = addsprite("ablak_nagy", 0, 0, w, h, true );
					ablakszoveg = addtext( w/2, h/2-100, Gateway.kezdoszovegek[Gateway.currentstep], "70px RobotoRegular", "#000", 1500, "center", 1);				
					
					scope.stage.update();				
					ablakszoveg.y = h/2-ablakszoveg.getBounds().height/2;
					scope.stage.update();
					
					uzenet = addtext( 50, h-100, szovegek["start"], "40px RobotoRegular", "#ffffff", 1250, "left", 1);
					uzenet.alpha = 0;
					
					logo = addsprite ("logo", 0, 50);
					logo.addEventListener("mousedown", fomenube);
					
					bar = addsprite ("bar", w-50-577, 50);

					createjs.Ticker.timingMode = createjs.Ticker.TIMEOUT;
					createjs.Ticker.framerate = myrate;
					createjs.Ticker.addEventListener("tick", tick);
				    createjs.Ticker.addEventListener("tick", createjs.Tween);
				   
					scope.onProxyMessage( {command: 'ready', param: ''} );
					
					updatepalyaszam();
					updatepontszam();

					scope.stage.update();
										
				   	createjs.Touch.enable(scope.stage);
					scope.stage.enableMouseOver(10);
					scope.stage.mouseMoveOutside = true;
					
					scope.stage.addEventListener("stagemousedown", handleComplete2);
					
			   }


               function handleComplete2() {
				   
					kihagy = addsprite ( "kihagy", kihagypos[0], kihagypos[1] );
					kihagy.addEventListener("mousedown", kattintas);
					kihagy.alpha = 1;
				   
				    if (Gateway.isios){
					   Gateway.audio.powerup.play();
				   }else{
						createjs.Sound.play("powerup");
				   }
				   				   
					scope.stage.removeEventListener("stagemousedown", handleComplete2);
				   	ablak_nagy.alpha=0;
					ablakszoveg.alpha=0;
					
					//Gomb rajzolása
					ujra = addsprite ( "ujra", ujrapos[0], ujrapos[1] );
					ujra.addEventListener("mousedown", kattintas);
					ujra.alpha = 1;

					tovabb = addsprite ( "tovabb", tovabbpos[0], tovabbpos[1] );
					tovabb.addEventListener("mousedown", kattintas);
					tovabb.alpha = 0;


					tipp = addsprite ( "tipp", tipppos[0], tipppos[1] );
					tipp.addEventListener("mousedown", kattintas);
					tipp.alpha = 1;

					szovegkijelzes ("start");
					scope.stage.update();
					
					//var myGraphics = new createjs.Graphics();
					//myGraphics.beginStroke("red").beginFill("blue").drawRect(0, 0, 100, 50);
					//stage.addChild(myGraphics);
					
					//var uzenet = new createjs.Text("abc", "bold 16px Courier", "#fff");
					
					//var container = new createjs.Container();
					//stage.addChild(container);
					
					//container.addChild(myGraphics, uzenet);
					//container.x = 100;
					//container.y = 100;
					
					//Elejex, eleje y , végex, végey, +x, +y, db
					
					var i;
					for (i = 0; i < vonalak.length; i++) {

						var vonal = vonalak[i];
						var lepes = vonal[6];
						
						var j;
						for (j = 0; j < lepes; j++) {

							var line2 = new createjs.Shape();
							scope.stage.addChild(line2);
							line2.graphics.setStrokeStyle(1).beginStroke("#FFFFFF");
							line2.graphics.moveTo(vonal[0]+vonal[4]*j,vonal[1]+vonal[5]*j);
							line2.graphics.lineTo(vonal[0]+vonal[2]+vonal[4]*j, vonal[1]+vonal[3]+vonal[5]*j);
							line2.graphics.endStroke();
							
						}
					  
					}
					for (i = 0; i < idoszovegek.length; i++) {

						var vonal = vonalak[1];
						//[500, 300, 0, 300, 50, 0, 17]
						addtext( vonal[0]+i*vonal[4]+vonal[4]/2, vonal[1]+vonal[4]/3, idoszovegek[i] , "25px RobotoBold", "#FFFFFF", 50, "center", true);

					  
					}					
					for (i = 0; i < ugyintezoszovegek.length; i++) {

						var vonal = vonalak[0];
						//[200, 300, 1600, 0, 0, 75, 7],
						addtext( vonal[0]+vonal[5]/3, vonal[1]+vonal[5]*(i+1)+vonal[5]/3, ugyintezoszovegek[i] , "25px RobotoBold", "#FFFFFF", 200, "left", true);

					  
					}						

					//var huzhatok = [];

					var pointerx = 2;
					var egyseg = vonalak[1][4];
					var gap = 2;
					var pointery = vonalak[1][1] + vonalak[1][3] + gap;

					for (i = 0; i < feladatok.length; i++) {

						var feladat = feladatok[i];
						var szin = szinek[i];
						
						if (pointerx + egyseg * feladat > w){
							pointerx = 2;
							pointery += gap + egyseg;
						}
						
						var container = new createjs.Container();						
						//var graphics = new createjs.Graphics().beginFill(szin).drawRect( pointerx, pointery, feladat*egyseg, egyseg);
						var graphics = new createjs.Graphics().beginFill(szin).drawRect( 0, 0, feladat*egyseg, egyseg);
						var sprite = new createjs.Shape(graphics);
						
						scope.stage.addChild(sprite);
						
						//var mytext = addtext( pointerx + 10, pointery + egyseg/3,feladat , "25px RobotoBold", "#000000", 200, "left", true);
						var mytext = addtext( 10, egyseg/3,feladat , "25px RobotoBold", "#000000", 100, "left", true);
						
							
						container.addChild(sprite, mytext);
						scope.stage.addChild(container);
						
						container.x = pointerx;						
						container.y = pointery;
						
						container.origx = pointerx;	
						container.origy = pointery;
						container.ertek = feladat;
						container.locked = 0;

						pointerx += feladat*egyseg + gap;
						
						container.mouseChildren = false;
						
						huzhatok.push(container);
						
						container.on("click", function(evt) {
							if (evt.target.locked) return;
							scope.stage.setChildIndex( evt.target, scope.stage.getNumChildren()-1);
						
						});
						
						container.on("pressmove", function(evt) {
							
							if (evt.target.locked) return;
							
							evt.target.x = evt.stageX / scope.stage.scaleX ;
							evt.target.y = evt.stageY / scope.stage.scaleY ;
							
							//console.log (evt.target.y);
							//console.log (evt.stageY);
							
						});
						container.on("pressup", function(evt) { 

							//[200, 200, 1600, 0, 0, 75, 7],
							if (evt.target.locked) return;
							
							if (evt.target.y > vonalak[0][1] + vonalak[0][5]  && evt.target.y < vonalak[0][1] + vonalak[0][5]* ( vonalak[0][6] -1) ){
								
								console.log (  Math.floor(( evt.target.y - vonalak[0][1]- vonalak[0][5]) / vonalak[0][5]) );
								//console.log (evt.target.y);
								
								var sorindex =  Math.floor(( evt.target.y - vonalak[0][1]- vonalak[0][5]) / vonalak[0][5]);
								
								if ( telitettseg[sorindex] + evt.target.ertek <= telitettsegmax ){
									
									//[400, 200, 0, 450, 75, 0, 17]
									
									evt.target.y = vonalak[0][1] + vonalak[0][5] + vonalak[0][5]*sorindex;
									evt.target.x = vonalak[1][0] + vonalak[0][5]*telitettseg[sorindex];
									telitettseg[sorindex] += evt.target.ertek;
									evt.target.locked = 1;
									
								}else{
									evt.target.x = evt.target.origx;
									evt.target.y = evt.target.origy;
								}
								
							}else{
								
								evt.target.x = evt.target.origx;
								evt.target.y = evt.target.origy;
								
							}

							if (telitettseg[0]==telitettsegmax && telitettseg[1]==telitettsegmax && telitettseg[2]==telitettsegmax && telitettseg[3]==telitettsegmax && telitettseg[4]==telitettsegmax ){

								szovegkijelzes ("kesz");
								tovabb.alpha = 1;
								kihagy.alpha=0;
							}


						})
					  
					}					

					//console.log (huzhatok);

					
			   }
			   
			   
			   function sugotmutat (){
				   
				    scope.stage.setChildIndex( ablak_nagy, scope.stage.getNumChildren()-1);
				    ablak_nagy.alpha=1;
				   
				    scope.stage.setChildIndex( tippszoveg, scope.stage.getNumChildren()-1);
				   
				   
					tippszoveg.y = h/2-tippszoveg.getBounds().height/2;
					scope.stage.update();
					
					tippszoveg.alpha=1;
				    scope.stage.addEventListener("stagemousedown", sugokikapcs);
				   
			   }
			   
			   function sugokikapcs (){
				    scope.stage.removeEventListener("stagemousedown", sugokikapcs);
				   	ablak_nagy.alpha=0;
					tippszoveg.alpha=0;
			   }
			   
			   function addtostage (id, x, y, w, h){
					var shape;
					shape = new createjs.Shape();
					shape.graphics.beginBitmapFill(loaderSvcLepes2.getResult(id)).drawRect(x, y, w, h);
					scope.stage.addChild(shape);
					return shape;
			   }
			   
			   function addsprite (id, x, y){
				   var bitmap;
				   bitmap = new createjs.Bitmap(loaderSvcLepes2.getResult(id));
				   bitmap.x = x;
				   bitmap.y = y;
				   scope.stage.addChild(bitmap);
				   return bitmap;
			   }
			   
			   	function storagesave(){
				   
				   	var storage = window.localStorage;

					storage.setItem("currentstep", Gateway.currentstep );
					storage.setItem("palya", Gateway.palya);
					storage.setItem("pont", Gateway.pont);
					storage.setItem("pontkezdo", Gateway.pontkezdo );
				   
			   }
			   
			   function fomenube(){
				   
				   	$rootScope.loading = true;					
			   		createjs.Ticker.reset();
					scope.stage.removeAllChildren();
					scope.stage.removeAllEventListeners();
					loaderSvcLepes2.getLoader().removeEventListener("complete", handleComplete);
				   
				   scope.onProxyMessage( {command: 'goto', param: "/fomenu"} );
			   }
			   
			   
			   function szovegkijelzes (id){
				 
				    visszaallitas = Gateway.szovegvisszaallas;
					uzenet.text = szovegek[id];
				    uzenet.alpha = 1;
			   }
			   

			   function addtext (xpos, ypos, text, font, mycolor, mywidth, myalign, myalpha ){
				   var myshape;

					var offsetx = 0;
					var offsety = 0;
				
				   if (xpos<0) xpos=w+xpos;
				   if (ypos<0) ypos=h+ypos;
				   
				   myshape = new createjs.Text(text, font, mycolor);
				   myshape.x = xpos + offsetx;
				   myshape.y = ypos + offsety ;
				   myshape.name = text;
				   
				   scope.stage.addChild(myshape);

				   myshape.lineWidth=mywidth;
				   myshape.textAlign=myalign;
				   myshape.alpha=myalpha;
				   
				   return myshape;
			   }
			   
			    function addsprite (id, x, y){
				   var bitmap;
				   bitmap = new createjs.Bitmap(loaderSvcLepes2.getResult(id));
				   bitmap.name = id;
				   bitmap.x = x;
				   bitmap.y = y;
				   scope.stage.addChild(bitmap);
				   return bitmap;
			   }			   
			   
				function updatepalyaszam (){
					if (palyaszam) scope.stage.removeChild(palyaszam);
					palyaszam = addtext( w-50-420, 75+Gateway.fontdiff, Gateway.palya + "." , "35px RobotoBold", "#FFFFFF", 100, "left", true);
				}

				function updatepontszam (){
					if (pontszam) scope.stage.removeChild(pontszam);
					pontszam = addtext( w-50-110, 75+Gateway.fontdiff, Gateway.pont , "35px RobotoBold", "#FFFFFF", 100, "left", true);
				}
				

			   
			   function tick(event) {

					if (!lock){
						if (visszaallitas>0){
							visszaallitas--;
						}else{
							uzenet.alpha = 0;
						}
					}
					
                   scope.stage.update(event);
               }

				function ujrarajzol(){
					
					console.log ("Újrarajzolás");
				
					var i;
					for (i = 0; i < huzhatok.length; i++) {
				
						huzhatok[i].locked = 0;
						huzhatok[i].x = huzhatok[i].origx;
						huzhatok[i].y = huzhatok[i].origy;
				
					}
					
					telitettseg = [0,0,0,0,0];
				
					szovegkijelzes ("start");
					tovabb.alpha = 0;
					kihagy.alpha=1;
				
				}
				
				function reset(){
					
					ujrarajzol();
					
				}
				

			   function kattintas (event){
				   

					var nev = event.target.name;

					if (nev == "ujra"){
						reset();
						return;
					}

					if (nev == "kihagy"){
						//Gateway.pont += 100;
						Gateway.palya += 1;					
						storagesave();
						
						if (Gateway.isios){
							Gateway.audio.drainmagic.play();
						}else{
							createjs.Sound.play("drainmagic");
						}
						closeandjump();
						return;
					}

					if (nev == "tovabb"){
						
						Gateway.pont += 100;
						Gateway.palya += 1;					
						storagesave();
						
						if (Gateway.isios){
							Gateway.audio.drainmagic.play();
						}else{
							createjs.Sound.play("drainmagic");
						}
						closeandjump();
						return;
					}

					if (nev == "tipp"){
						sugotmutat();
						return;
					}
					
					if (lock)return
					
				   if (Gateway.isios){
					   Gateway.audio.swish.play();
				   }else{
					   createjs.Sound.play("swish");
				   }


					
			   }
			   
			   function closeandjump(step){
					
					$rootScope.loading = true;					
			   		createjs.Ticker.reset();
					scope.stage.removeAllChildren();
					scope.stage.removeAllEventListeners();
					loaderSvcLepes2.getLoader().removeEventListener("complete", handleComplete);
					
					var ide;
					
					if (step === undefined){
						ide=Gateway.steps[++Gateway.currentstep];
						if (ide === undefined){
							Gateway.currentstep = 0;
							ide=Gateway.steps[Gateway.currentstep];
						}
					}else{
						Gateway.currentstep = step;
						ide=Gateway.steps[Gateway.currentstep];
					}
					
					scope.onProxyMessage( {command: 'goto', param: ide} );
					
			   }
			   
           }
       }
   }]);
angular.module('BGEHeroes.services.Cordova', [])

.factory('deviceReady', function(){
  return function(done) {
    if (typeof window.cordova === 'object') {
		document.addEventListener('deviceready', function () {
		type = "application";  
        done();
      }, false);
    } else {
      done();
    }
  };
});
angular.module('BGEHeroes.services.Gateway', [
]).factory('Gateway', ['$log', '$http', '$location', '$cookies', '$window', '$route',  function($log, $http, $location, $cookies, $window, $route){

  var Gateway = {
		remote_host: 'https://bgeheroes.panemnet.hu/eredmenyek.php',
		firstboot: 0,
		loggedin: 0,
		logindata: [],
		audio: {},
		loginmessage: "",
		iscordova: false,
		isios: false,
		cordovaprefix: "",
		devmode: true,
		jatekdev: false,
		hotspotvisszaallas: 20,
		szovegvisszaallas: 60,
		fontdiff: 0,
		palya: 0,
		pont: 0,
		pontkezdo: 0,
		framerate: 30,
		currentstep: 0,
		jatekvegesikertelen: "A feladat végrehajtása sikertelen volt. Kattints az Újra gombra!",
		jatekvegejo: "A feladatot végrehajtottad. Kattints az Újra vagy a Tovább gombra!",
		jatekvegekituno: "A feladatot kiválóan végrehajtottad. Kattints a Tovább gombra!",
		kezdoszovegek: [
			//Black szövege
			"-",
			
			//Főoldal szövege
			"Köszöntünk virtuális játékunkban! A játék során váltakozva lesznek logikai, számolós és quiz jellegű pályák. Keress, kísérletezz, játssz, hiszen nincs megoldhatatlan feladat.", //0.
			
			//Lépés 1 szövege
			"Anna, Bogi, Cili és Dóri egy sötét, szűk alagúton szeretnének átjutni. Az alagút annyira sötét, hogy lámpás nélkül nem mehetnek és annyira szűk, hogy legfeljebb ketten férnek át rajta egyszerre. Anna 1, Bogi 2, Cili 4, Dóri pedig 5 perc alatt képes megtenni az utat. Hogyan juthatnak mindannyian át az alagúton, ha egy 12 percig égő lámpásuk van?", //1.
			
			//Quiz 1 szövege
			"Összesen hányféleképpen haladhatnak át, ha ugyanez az alaphelyzet, de a lámpás akármeddig képes világítani? (Két áthaladás különböző, ha van olyan lépése, ahol nem ugyanazok az emberek szerepelnek benne.)",
			
			//Lépés 2 szövege
			"Egy ügyfélszolgálaton az előzetes bejelentkezések alapján ismert, hogy milyen ügyeket kell elvégezni egy adott napon, valamint szintén ismert ezeknek a várható ügyintézési ideje. Az adott ügyintézések megfelelő helyre húzásával készítsd el az öt ügyintéző egy lehetséges munkabeosztását!",

			//Quiz 2 szövege
			"Az ügyfélszolgálaton várakozunk. Melyik ablakhoz szólították a következő ügyfelet, ha a váróban, széken ülve, az előtte lévő üvegasztalon így látja tükröződni a falon lévő digitális kijelzőt? ",

			//Lépés 3 szövege
			"A matematika egyik nevezetes tétele a négyszín-tétel, amely azt mondja ki, hogy bárhogyan is osztjuk tartományokra a síkot, mindig ki lehet színezni legfeljebb négy szín felhasználásával úgy, hogy semelyik két szomszédos tartomány ne legyen azonos színű. Ezt a szabályt betartva színezd ki az alábbi ábrát!",

			//Quiz 3 szövege
			"Következik egy feladvány, az előző feladatban látott ábrához kapcsolódóan.",

			//Lépés 4 szövege
			"Mérj ki pontosan 6 liter vizet úgy, hogy egy 4 és egy 9 literes kanna áll rendelkezésre! (A szökőkútból korlátlan mennyiségű víz áll rendelkezésre, és oda bármennyit ki is önthetünk.)",

			//Quiz 4 szövege
			"Tedd próbára térgeometriai ismereteidet!",

			//Lépés 5 szövege
			"Egy matematikus 100 emeletes szállodájában 4 lift működik egymás mellett. Azonban ezek nem hagyományosan működnek, hanem a következőképpen: mindig pontosan két lift mozoghat egyszerre és vagy 7 szinttel feljebb, vagy 11 szinttel lejjebb megy mindkettő. A lift ajtók akkor nyílnak ki, ha mind a négy lift a földszinten áll. Szabadítsd ki a liftben rekedt vendégeket, ha liftek jelenleg az 5., 8., 4., illetve az 1. emeleten állnak.",

			//Quiz 5 szövege
			"Az egyik liftben két matematikus ragadt bent. Hányadik emeleten lesz a Számelmélet előadás?",

			//Lépés 6 szövege
			"A folyó egyik partján állunk egy farkassal, egy kecskével és egy káposztával. Át szeretnénk jutni a túlpartra, amihez egy csónak áll rendelkezésre, amiben legfeljebb egy dolgot vihetünk magunkkal. Viszont ha egyedül hagyjuk a kecskét a káposztával, akkor megeszi. Hasonlóképpen a farkast és a kecskét sem szabad magukra hagyni. Hogyan juthat át mindenki épségben?", //1.

			//Quiz 6 szövege
			"A folyó partján kocka alakú dobozokból álló rakomány áll, hajózáshoz előkészítve, mely megmutatunk oldalnézetből, illetve szemből. Hány dobozból állhat a rakomány?",

			//Vége szövege
			"A játék véget ért! Gratulálunk a megszerzett pontszámhoz. Kattintson a képernyőre az újrakezdéshez!"

		],
		steps: [
					"/",			//0.
					"/fomenu",		//1. 
					"/lepes1",     //2.  Alagutas
					"/quizgame1",    //3.  Kérdések alagutashoz
					"/lepes2",     //4.  Ügyfélszolgálat időbeosztás
					"/quizgame2",    //5.  
					"/lepes3",      //6  
					"/quizgame3",    //7 
					"/lepes4",      //8  
					"/quizgame4",    //9 
					"/lepes5",      //10  
					"/quizgame5",    //11 
					"/lepes6",      //11  
					"/quizgame6",    //12
					"/vege"    //9 
				]
	};
	
		//Képernyőméretezés miatt
	   if (Gateway.firstboot){
		   Gateway.firstboot = 0;
		   $route.reload();
		   return;
	   }
	
	for (var i=0; i<Gateway.steps.length;i++){
		if ($location.path() == Gateway.steps[i]){
			Gateway.currentstep = i;
			
			//if ( Gateway.currentstep>=14){
			//	Gateway.palya = Gateway.currentstep -3;
			//} else if (Gateway.currentstep>=9){
			//	Gateway.palya = Gateway.currentstep -2;
			//}else{
				
			Gateway.palya = Gateway.currentstep-1;
			
			//}

			break;
		}
	}
	
	function isPhoneGap() {
		return ($window.cordova || $window.PhoneGap || $window.phonegap) 
		&& /^file:\/{3}[^\/]/i.test($window.location.href) 
		&& /ios|iphone|ipod|ipad|android/i.test(navigator.userAgent);
	}

	//console.log ($window.cordova);
	
	if ( isPhoneGap() ) {

	    Gateway.iscordova = true;
		
		if ( /ios|iphone|ipod|ipad/i.test(navigator.userAgent) ){
			Gateway.fontdiff=-7;
			Gateway.isios = true;
			Gateway.hotspotvisszaallas = 8;
		}
		
		
		Gateway.cordovaprefix = $window.cordova.file.applicationDirectory + "www";
		console.log ("applicationDirectory: " + $window.cordova.file.applicationDirectory);
	} else {
	}
	
	createjs.Sound.registerSound({id:"intro", src: Gateway.cordovaprefix + "/images/sounds/447522__gbatzler__dramatic-production-logo.ogg"});
	createjs.Sound.registerSound({id:"break", src: Gateway.cordovaprefix + "/images/sounds/break.ogg"});
   	createjs.Sound.registerSound({id:"powerup", src: Gateway.cordovaprefix + "/images/sounds/Powerup.ogg"});
   	createjs.Sound.registerSound({id:"utkozes", src: Gateway.cordovaprefix + "/images/sounds/Collision8-Bit.ogg"});
   	createjs.Sound.registerSound({id:"crush", src: Gateway.cordovaprefix + "/images/sounds/Crush8-Bit.ogg"});
   	createjs.Sound.registerSound({id:"death", src: Gateway.cordovaprefix + "/images/sounds/death.ogg"});
   	createjs.Sound.registerSound({id:"drainmagic", src: Gateway.cordovaprefix + "/images/sounds/DrainMagic.ogg"});
   	createjs.Sound.registerSound({id:"enemydeath", src: Gateway.cordovaprefix + "/images/sounds/EnemyDeath.ogg"});
   	createjs.Sound.registerSound({id:"shot", src: Gateway.cordovaprefix + "/images/sounds/shot.ogg"});
   	createjs.Sound.registerSound({id:"slash", src: Gateway.cordovaprefix + "/images/sounds/Slash8-Bit.ogg"});
   	createjs.Sound.registerSound({id:"explosion", src: Gateway.cordovaprefix + "/images/sounds/SmallExplosion8-Bit.ogg"});
   	createjs.Sound.registerSound({id:"swish", src: Gateway.cordovaprefix + "/images/sounds/Soft_Airy_Swish.ogg"});
   	createjs.Sound.registerSound({id:"male", src: Gateway.cordovaprefix + "/images/sounds/Male_standard_4.ogg"});
   	createjs.Sound.registerSound({id:"uj_helyes", src: Gateway.cordovaprefix + "/images/sounds/uj_helyes.ogg"});
   	createjs.Sound.registerSound({id:"uj_rossz", src: Gateway.cordovaprefix + "/images/sounds/uj_rossz.ogg"});
   	createjs.Sound.registerSound({id:"uj_utkozes", src: Gateway.cordovaprefix + "/images/sounds/uj_utkozes.ogg"});
   	createjs.Sound.registerSound({id:"uj_robbanas", src: Gateway.cordovaprefix + "/images/sounds/uj_robbanas.ogg"});
	
	if (Gateway.isios){
		
		function audio_win (response){
			//console.log ("Audio Success");
		}
		
		function audio_fail (response){
			//console.log ("Audio Fail");
		}
		
		Gateway.audio = {
			
			intro: new Media ( "images/sounds2/447522__gbatzler__dramatic-production-logo.m4a"),
			break: new Media ( "images/sounds2/break.m4a"),
			powerup: new Media ( "images/sounds2/Powerup.m4a"),
			utkozes: new Media ( "images/sounds2/Collision8-Bit.m4a"),
			crush: new Media ( "images/sounds2/Crush8-Bit.m4a"),
			death: new Media ( "images/sounds2/death.m4a"),
			drainmagic: new Media ( "images/sounds2/DrainMagic.m4a"),
			enemydeath: new Media ( "images/sounds2/EnemyDeath.m4a"),
			shot: new Media ( "images/sounds2/shot.m4a"),
			slash: new Media ( "images/sounds2/Slash8-Bit.m4a"),
			explosion: new Media ( "images/sounds2/SmallExplosion8-Bit.m4a"),
			swish: new Media ( "images/sounds2/Soft_Airy_Swish.m4a"),
			male: new Media ( "images/sounds2/Male_standard_4.m4a"),
			uj_helyes: new Media ( "images/sounds2/uj_helyes.m4a"),
			uj_rossz: new Media ( "images/sounds2/uj_rossz.m4a"),
			uj_utkozes: new Media ( "images/sounds2/uj_utkozes.m4a"),
			uj_robbanas: new Media ( "images/sounds2/uj_robbanas.m4a"),
		};
		
	}
	
	
	//window.screen.orientation.lock('portrait')

	/*
    screen.orientation.lock('landscape').then(function success() {
    console.log("Successfully locked the orientation");
	}, function error(errMsg) {
		console.log("Error locking the orientation :: " + errMsg);
	});
	*/
	
	$http.defaults.headers.post["Content-Type"] = "text/plain";		

	
	Gateway.reset = function () {
		//Gateway.cities = [];
	}


  return Gateway;
}]);
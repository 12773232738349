angular.module('BGEHeroes.directives.Lepes1', [])

.directive('lepes1', ['loaderSvcLepes1', '$location', 'Gateway', '$rootScope', function (loaderSvcLepes1, $location, Gateway, $rootScope) {
       "use strict";
       return {
           restrict : 'EAC',
           replace : true,
           scope :{
			    onProxyMessage: '&'
           },
           template: "<canvas></canvas>",
           link: function (scope, element, attribute) {
			   
               var w, h, lepes;
			   
			   var hatter1, hatter2, bar, logo, lampas, lampastext, tovabb, ujra, kihagy;
			   
			   var STAGE_WIDTH, STAGE_HEIGHT, STAGE_ASPECT_RATIO, scope;
			   var visszaallitas;
			   var kattintva;
			   
			   var ablak_nagy, ablakszoveg, tippszoveg;
			   var tipp;
			   var tipppos = [1920-265, 950-150-150];
			   
			   var palyaszam, pontszam;
			   
			   var indulopontszam;
			   var eletekszama;
			   var myrate = Gateway.framerate;
			   
			   var leftpointer = 0;
			   var rightpointer = 0;
			   
			   var hozzavalok = {};
			   
			   var kihagypos = [1920-216, 950];
			   
			   var jobbrapos = [900, 750];
			   var balrapos = [1920-216-600, 750];

			   var ujrapos = [1920-216, 950-150];
			   var tovabbpos = [1920-216-300, 950];

			   
			   var balra, jobbra;
			   
			   var palyaszam, pontszam;
			   
			   var uzenet;
			   
			   var lock;
			   var megoldva;
			   
			   var szovegek = {
				   
				   start: "Válaszd ki, ki menjen át, majd kattints a nyíl gombra.",
				   katt: "Gondold meg jól, kik menjenek át egyszerre. A lámpás ideje annyival fog fogyni, mint amennyi a kiválasztott leglassabb gyermek ideje.",
				   kattmasik: "Csak azon az oldalon lehet választani, ahol a lámpás van, mert a gyerekek a lámpással mennek át.",
				   kattsok: "Egyszerre maximum 2-en mehetnek csak át.",
				   kattnincs: "Sajnos kevés a lámpás ideje ehhez a választáshoz.",
				   kesz: "Gratulálunk, a feladat megoldása sikeres volt!",
				   nem: "Sajnos a feladat idő hiányában nem megoldható!"
				   
				   
			   };
			   
			   
			   var leftpos = [
								[430, 570],
								[600, 570],
								[430, 750],
								[600, 750],
							];
			   
			   var rightpos = [
								[1300, 570],
								[1470, 570],
								[1300, 750],
								[1470, 750],
							];


				var lampas;
				var lampastext;
				var lampaspointer = 0;
				var lampaspoz = [ [310, 200], [1500, 200] ];

				var fejek = ["fej1", "fej2", "fej3", "fej4"];

				var fejertekek = { fej1:1, fej2:2, fej3:4, fej4:5 };
				
				var lampasertekdef = 12;
				var lampasertek = 0;
			   
				var kontenerek = [];
			   
				var valasztasokszama = 0;
				var valasztottertek = 0;
			   
			   lepes=0;
									
               drawGame();
               function drawGame() {
                   if (scope.stage) {
                       scope.stage.autoClear = true;
                       scope.stage.removeAllChildren();
                       scope.stage.update();
                   } else {
                       scope.stage = new createjs.Stage(element[0]);
                   }
                   w = scope.stage.canvas.width = STAGE_WIDTH = 1920;
                   h = scope.stage.canvas.height = STAGE_HEIGHT = 1080;
				   
					loaderSvcLepes1.getLoader().addEventListener("complete", handleComplete);
					loaderSvcLepes1.loadAssets();

					/////////////////////////////////////////
					STAGE_ASPECT_RATIO = STAGE_WIDTH / STAGE_HEIGHT;
					var containerWidth = window.innerWidth;
					var containerHeight = window.innerHeight;
					var brratio = containerWidth/containerHeight;
					if (STAGE_ASPECT_RATIO>brratio){
						//A Stage magasabb. Teljes szélesség, a magasságot kell korlátozni.
						scope.stage.canvas.width = containerWidth;
						scope.stage.canvas.height = containerWidth / ref;
						   
					}else{
						//A Stage szélesebb. Teljes magasság, a szélességet kell korlátozni.
						scope.stage.canvas.width = containerHeight * ref;
						scope.stage.canvas.height = containerHeight;
					}
					// scale stage to fit canvas
					var scalex = scope.stage.canvas.width / STAGE_WIDTH;
					var scaley = scope.stage.canvas.height / STAGE_HEIGHT;
					scope.stage.scaleX = scalex;
					scope.stage.scaleY = scaley;
					scope.stage.update();					
					/////////////////////////////////////////////
				   
               }

			   function sugotmutat (){
				   
				    scope.stage.setChildIndex( ablak_nagy, scope.stage.getNumChildren()-1);
				    ablak_nagy.alpha=1;
				   
				    scope.stage.setChildIndex( tippszoveg, scope.stage.getNumChildren()-1);
				   
				   
					tippszoveg.y = h/2-tippszoveg.getBounds().height/2;
					scope.stage.update();
					
					tippszoveg.alpha=1;
				    scope.stage.addEventListener("stagemousedown", sugokikapcs);
				   
			   }
			   
			   	  function sugokikapcs (){
				    scope.stage.removeEventListener("stagemousedown", sugokikapcs);
				   	ablak_nagy.alpha=0;
					tippszoveg.alpha=0;
			   }
			   
			   function addtostage (id, x, y, w, h){
					var shape;
					shape = new createjs.Shape();
					shape.graphics.beginBitmapFill(loaderSvcLepes1.getResult(id)).drawRect(x, y, w, h);
					scope.stage.addChild(shape);
					return shape;
			   }
			   
			   function addsprite (id, x, y){
				   var bitmap;
				   bitmap = new createjs.Bitmap(loaderSvcLepes1.getResult(id));
				   bitmap.x = x;
				   bitmap.y = y;
				   scope.stage.addChild(bitmap);
				   return bitmap;
			   }
			   
			   	function storagesave(){
				   
				   	var storage = window.localStorage;

					storage.setItem("currentstep", Gateway.currentstep );
					storage.setItem("palya", Gateway.palya);
					storage.setItem("pont", Gateway.pont);
					storage.setItem("pontkezdo", Gateway.pontkezdo );
				   
			   }
			   
               function handleComplete() {
				   	
					lock = false;
					
					Gateway.pontkezdo = Gateway.pont;
					storagesave();
					
				   	//Gateway.pont += 100;
					if (!Gateway.palya) Gateway.palya += 1;					

					visszaallitas = 0;
				   
					hatter1 = addtostage ("hatter1", 0, 0, w, h);
					
					ablak_nagy = addsprite("ablak_nagy", 0, 0, w, h, true );
					ablakszoveg = addtext( w/2, h/2-100, Gateway.kezdoszovegek[Gateway.currentstep], "70px RobotoRegular", "#000000", 1500, "center", 1);				
					scope.stage.update();				
					ablakszoveg.y = h/2-ablakszoveg.getBounds().height/2;
					scope.stage.update();

					tippszoveg = addtext( w/2, h/2-100, Gateway.kezdoszovegek[Gateway.currentstep], "70px RobotoRegular", "#000", 1500, "center", 1);				
					tippszoveg.alpha = 0;
					
					uzenet = addtext( +340, h-100, szovegek["start"], "40px RobotoRegular", "#000", 1400, "left", 1);
					uzenet.alpha = 0;
					
					logo = addsprite ("logo", 0, 40);
					logo.addEventListener("mousedown", fomenube);
					
					bar = addsprite ("bar", w-50-577, 50);

					createjs.Ticker.timingMode = createjs.Ticker.TIMEOUT;
					createjs.Ticker.framerate = myrate;
					createjs.Ticker.addEventListener("tick", tick);
				    createjs.Ticker.addEventListener("tick", createjs.Tween);
				   
					scope.onProxyMessage( {command: 'ready', param: ''} );
					
					updatepalyaszam();
					updatepontszam();

					scope.stage.update();
										
				   	createjs.Touch.enable(scope.stage);
					scope.stage.enableMouseOver(10);
					scope.stage.mouseMoveOutside = true;
					
					scope.stage.addEventListener("stagemousedown", handleComplete2);
					
			   }
			   
			   function fomenube(){
				   
				   	$rootScope.loading = true;					
			   		createjs.Ticker.reset();
					scope.stage.removeAllChildren();
					scope.stage.removeAllEventListeners();
					loaderSvcLepes1.getLoader().removeEventListener("complete", handleComplete);
				   
				   scope.onProxyMessage( {command: 'goto', param: "/fomenu"} );
			   }
			   
			   
			   function szovegkijelzes (id){
				 
				    visszaallitas = Gateway.szovegvisszaallas;
					uzenet.text = szovegek[id];
				    uzenet.alpha = 1;
			   }
			   
               function handleComplete2() {
				   
					
					lampasertek = lampasertekdef;
				   
				    if (Gateway.isios){
					   Gateway.audio.powerup.play();
				   }else{
						createjs.Sound.play("powerup");
				   }
				   				   
					scope.stage.removeEventListener("stagemousedown", handleComplete2);
				   	ablak_nagy.alpha=0;
					ablakszoveg.alpha=0;

					kihagy = addsprite ( "kihagy", kihagypos[0], kihagypos[1] );
					kihagy.addEventListener("mousedown", kattintas);
					kihagy.alpha = 1;

					tipp = addsprite ( "tipp", tipppos[0], tipppos[1] );
					tipp.addEventListener("mousedown", kattintas);
					tipp.alpha = 1;
					
					//Maszkolható háttér betöltése
					//hatter2 = addtostage ("hatter2", 0, 0, w, h);
					//hatter2.alpha = 0;
					
					//Fejek rajzolása					
					for (var i = 0; i < fejek.length; i++) { 

						hozzavalok[fejek[i]] = [];

						//Keret
						var tmpsprite = addsprite ( "keret", leftpos[leftpointer][0], leftpos[leftpointer][1] );
						tmpsprite.alpha = 0;
						hozzavalok[fejek[i]].push(tmpsprite);

						//fejek
						tmpsprite = addsprite ( fejek[i], leftpos[leftpointer][0], leftpos[leftpointer][1] );
						hozzavalok[fejek[i]].push(tmpsprite);

						//Kiválasztva
						hozzavalok[fejek[i]].push(0);
						
						//Oldal
						hozzavalok[fejek[i]].push(0);
						
						scope.stage.update();
						
						var szelesseg = tmpsprite.getBounds().width;
						var magassag = tmpsprite.getBounds().height;
						//console.log (szelesseg);
						
						//felirat
						var tmptext = addtext( leftpos[leftpointer][0] + szelesseg/2, leftpos[leftpointer][1]+magassag, fejertekek[fejek[i]]+"" , "35px RobotoBold", "#000000", szelesseg, "center", 1);
						hozzavalok[fejek[i]].push(tmptext);

						tmpsprite.addEventListener("mousedown", kattintas);
						leftpointer++;
					}
					
					//Lámpás rajzolása
					lampas = addsprite ( "lampas", lampaspoz[0][0], lampaspoz[0][1] );
					var szelesseg = lampas.getBounds().width;
					var magassag = lampas.getBounds().height;
					lampastext = addtext( lampaspoz[0][0] + szelesseg/2, lampaspoz[0][1]+magassag-50, lampasertek , "35px RobotoBold", "#ffffff", szelesseg, "center", 1);
					
					//Gomb rajzolása
					
					jobbra = addsprite ( "jobbra", jobbrapos[0], jobbrapos[1] );
					jobbra.addEventListener("mousedown", kattintas);
					jobbra.alpha = 0;

					balra = addsprite ( "balra", balrapos[0], balrapos[1] );
					balra.addEventListener("mousedown", kattintas);
					balra.alpha = 0;


					ujra = addsprite ( "ujra", ujrapos[0], ujrapos[1] );
					ujra.addEventListener("mousedown", kattintas);
					ujra.alpha = 1;

					tovabb = addsprite ( "tovabb", tovabbpos[0], tovabbpos[1] );
					tovabb.addEventListener("mousedown", kattintas);
					tovabb.alpha = 0

					
					//console.log (hozzavalok);
					
					szovegkijelzes ("start");
					
					scope.stage.update();
										
			   }


			   function addtext (xpos, ypos, text, font, mycolor, mywidth, myalign, myalpha ){
				   var myshape;

					var offsetx = 0;
					var offsety = 0;
				
				   if (xpos<0) xpos=w+xpos;
				   if (ypos<0) ypos=h+ypos;
				   
				   myshape = new createjs.Text(text, font, mycolor);
				   myshape.x = xpos + offsetx;
				   myshape.y = ypos + offsety ;
				   myshape.name = text;
				   
				   scope.stage.addChild(myshape);

					//console.log (mywidth);

					//console.log (myalign);

				   myshape.lineWidth=mywidth;
				   myshape.textAlign=myalign;
				   myshape.alpha=myalpha;
				   
				   return myshape;
			   }
			   
			    function addsprite (id, x, y){
				   var bitmap;
				   bitmap = new createjs.Bitmap(loaderSvcLepes1.getResult(id));
				   bitmap.name = id;
				   bitmap.x = x;
				   bitmap.y = y;
				   scope.stage.addChild(bitmap);
				   return bitmap;
			   }			   
			   
				function updatepalyaszam (){
					if (palyaszam) scope.stage.removeChild(palyaszam);
					palyaszam = addtext( w-50-420, 75+Gateway.fontdiff, Gateway.palya + "." , "35px RobotoBold", "#FFFFFF", 100, "left", true);
				}

				function updatepontszam (){
					if (pontszam) scope.stage.removeChild(pontszam);
					pontszam = addtext( w-50-110, 75+Gateway.fontdiff, Gateway.pont , "35px RobotoBold", "#FFFFFF", 100, "left", true);
				}
				
				

			   
			   function tick(event) {

					if (!lock){
						if (visszaallitas>0){
							visszaallitas--;
						}else{
							uzenet.alpha = 0;
						}
					}
					
                   scope.stage.update(event);
               }

				function ujrarajzol(){
					//console.log ("Újrarajzolás");

						var balmax=0;



						leftpointer = 0;
						rightpointer = 0;
					
						lampastext.text = lampasertek;
					
						var szelesseg = lampas.getBounds().width;
						var magassag = lampas.getBounds().height;
						
						lampas.x = lampaspoz[lampaspointer][0];
						lampas.y = lampaspoz[lampaspointer][1];

						lampastext.x = lampaspoz[lampaspointer][0] + szelesseg/2;
						lampastext.y = lampaspoz[lampaspointer][1]+magassag-50;
					
						var mindjobb = 1;
					
						for (var i = 0; i < fejek.length; i++) { 
						
						
							if (hozzavalok[fejek[i]][3] == 0){

								if (fejertekek[fejek[i]]>balmax) balmax = fejertekek[fejek[i]];

								mindjobb = 0;


								//Bal oldal
								hozzavalok[fejek[i]][0].x = leftpos[leftpointer][0];
								hozzavalok[fejek[i]][0].y = leftpos[leftpointer][1];
								hozzavalok[fejek[i]][0].alpha = 0;

								hozzavalok[fejek[i]][1].x = leftpos[leftpointer][0];
								hozzavalok[fejek[i]][1].y = leftpos[leftpointer][1];

								var szelesseg = hozzavalok[fejek[i]][1].getBounds().width;
								var magassag = hozzavalok[fejek[i]][1].getBounds().height;

								hozzavalok[fejek[i]][4].x = leftpos[leftpointer][0] + szelesseg/2;
								hozzavalok[fejek[i]][4].y = leftpos[leftpointer][1]+magassag;
								leftpointer++;
							}
						
							if (hozzavalok[fejek[i]][3] == 1){
								//jobb oldal
								
								
								hozzavalok[fejek[i]][0].x = rightpos[rightpointer][0];
								hozzavalok[fejek[i]][0].y = rightpos[rightpointer][1];
								hozzavalok[fejek[i]][0].alpha = 0;

								hozzavalok[fejek[i]][1].x = rightpos[rightpointer][0];
								hozzavalok[fejek[i]][1].y = rightpos[rightpointer][1];

								var szelesseg = hozzavalok[fejek[i]][1].getBounds().width;
								var magassag = hozzavalok[fejek[i]][1].getBounds().height;

								hozzavalok[fejek[i]][4].x = rightpos[rightpointer][0] + szelesseg/2;
								hozzavalok[fejek[i]][4].y = rightpos[rightpointer][1]+magassag;
								rightpointer++;
							}
							
							hozzavalok[fejek[i]][2] = 0;
						}
						
						kihagy.alpha=1;
						if (mindjobb){
							szovegkijelzes ("kesz");
							megoldva = 1;
							lock = 1;
							ujra.alpha = 1;
							tovabb.alpha = 1;
							kihagy.alpha=0;
						}
						
						if (balmax > lampasertek){
							szovegkijelzes ("nem");
							megoldva = 0;
							lock = 1;
							ujra.alpha = 1;
						}
					
				}
				
				function reset(){
					
					//scope.stage.addEventListener("stagemousedown", handleComplete2);
					//ablak_nagy.alpha=1;
					//ablakszoveg.alpha=1;
					
					lampasertek = lampasertekdef;

					balra.alpha=0;
					jobbra.alpha=0;
					
					valasztottertek=0;
					valasztasokszama=0;
										
					ujra.alpha=1;
					tovabb.alpha=0;
					megoldva=0;
					lock=0;
					uzenet.alpha=0;
					lampaspointer=0;
					
					for (var i = 0; i < fejek.length; i++) { 
						hozzavalok[fejek[i]][2] = 0;
						hozzavalok[fejek[i]][3] = 0;
					}
					
					ujrarajzol();
					
				}
				

			   function kattintas (event){
				   
				   //if (visszaallitas) return;				   

					var nev = event.target.name;
					//console.log (nev);

					if (nev == "tipp"){
						sugotmutat();
						return;
					}

					if (nev == "ujra"){
						reset();
						return;
					}

					if (nev == "kihagy"){
						//Gateway.pont += 100;
						Gateway.palya += 1;					
						storagesave();
						
						if (Gateway.isios){
							Gateway.audio.drainmagic.play();
						}else{
							createjs.Sound.play("drainmagic");
						}
						closeandjump();
						return;
					}

					if (nev == "tovabb"){
						Gateway.pont += 100;
						Gateway.palya += 1;					
						storagesave();
						
						if (Gateway.isios){
							Gateway.audio.drainmagic.play();
						}else{
							createjs.Sound.play("drainmagic");
						}
						closeandjump();
						return;
					}


					
					if (lock)return
					
				   if (Gateway.isios){
					   Gateway.audio.swish.play();
				   }else{
					   createjs.Sound.play("swish");
				   }




					
					var kattintottertek = 0;				
					for (var i = 0; i < fejek.length; i++) { 
					
						if (fejek[i] == nev){
							
							if (hozzavalok[fejek[i]][2] == 0){
								kattintottertek = fejertekek[nev];
								break;
							}
						}
					
					}				
					if (kattintottertek<valasztottertek) kattintottertek = valasztottertek
					
					
					if (nev == "jobbra" && lampaspointer == 0 && valasztottertek <= lampasertek){
						//console.log ("Mehet jobbra");
					
					
						for (var i = 0; i < fejek.length; i++) { 
						
							if (hozzavalok[fejek[i]][2] == 1 &&  hozzavalok[fejek[i]][3] == 0){
								//console.log ("Kiválasztva" + fejek[i]);
								hozzavalok[fejek[i]][3] = 1;
								//szovegkijelzes ("katt");
							}

							hozzavalok[fejek[i]][2] = 0;

						}

						jobbra.alpha = 0;
						valasztasokszama = 0;
						
						
						
						lampasertek -= valasztottertek;
						valasztottertek = 0;
						lampaspointer = 1;						
						
						ujrarajzol();
						
						return;
					}

					if (nev == "balra" && lampaspointer == 1 && valasztottertek <= lampasertek){
						
						for (var i = 0; i < fejek.length; i++) { 
						
							if (hozzavalok[fejek[i]][2] == 1 &&  hozzavalok[fejek[i]][3] == 1){
								//console.log ("Kiválasztva" + fejek[i]);
								hozzavalok[fejek[i]][3] = 0;
								//szovegkijelzes ("katt");
							}
							
							hozzavalok[fejek[i]][2] = 0;
						}

						balra.alpha = 0;
						valasztasokszama = 0;
						lampasertek -= valasztottertek;
						valasztottertek = 0;
						lampaspointer = 0;						
						
						ujrarajzol();
						
						//console.log ("Mehet balra");
						return;
					}

					
					if ( hozzavalok[nev][3] == lampaspointer){
						if (kattintottertek <= lampasertek){
							if ( hozzavalok[nev][2] == 1 ){
								hozzavalok[nev][2] = 0;
								hozzavalok[nev][0].alpha=0;
								valasztasokszama--;
								//valasztottertek -= fejertekek[nev];
							}else{
								if (valasztasokszama<2){
									hozzavalok[nev][2] = 1;
									hozzavalok[nev][0].alpha=1;
									valasztasokszama++;
									//valasztottertek += fejertekek[nev];
									szovegkijelzes ("katt");
								}else{
									szovegkijelzes ("kattsok");
								}
							}
						}else{
							szovegkijelzes ("kattnincs");
						}
					}else{
						
						szovegkijelzes ("kattmasik");
						
					}
					
					valasztottertek = 0;
					for (var i = 0; i < fejek.length; i++) { 
						
							if (hozzavalok[fejek[i]][2] == 1){
								if ( fejertekek[fejek[i]] > valasztottertek) valasztottertek = fejertekek[fejek[i]];
							}
					}
					console.log (valasztottertek);
					
					if (valasztasokszama){
						if (lampaspointer){
							balra.alpha=1;
						}else{
							jobbra.alpha=1;
						}
					}else{
						if (lampaspointer){
							balra.alpha=0;
						}else{
							jobbra.alpha=0;
						}
					}



					
			   }
			   
               function stageinterakcio() {	   
			   
					if (lepes==0){
						lepes++;
						
						    Gateway.pont += 50;
							updatepontszam();
							
							//Lépés2
							//park1.graphics.beginBitmapFill(loaderSvcLepes1.getResult("park2")).drawRect(0, 0, w, h);
							//createjs.Tween.removeTweens ( park2 );
							if (Gateway.isios){
								Gateway.audio.swish.play();
							}else{
								createjs.Sound.play("swish");
							}
							createjs.Tween.get(park2).to({alpha:1}, 1000).call(effektvege1).wait(50);							
							scope.stage.removeAllEventListeners();
							
						return;
					}
			   
					if (lepes==1){
						return;
					}
			   
					closeandjump();
				   
               }


				function effektvege1 (){
					//Maszkolható háttér betöltése
					park3 = addtostage ("park3", 0, 0, w, h);
					park3.alpha = 0;
					
					//Hotspotok rajzolása					
					var i;
					for (i = 0; i < hotspots.length; i++) { 
						addhotspot(i);
					}		
				}

			   
			   function closeandjump(step){
					
					$rootScope.loading = true;					
			   		createjs.Ticker.reset();
					scope.stage.removeAllChildren();
					scope.stage.removeAllEventListeners();
					loaderSvcLepes1.getLoader().removeEventListener("complete", handleComplete);
					
					var ide;
					
					if (step === undefined){
						ide=Gateway.steps[++Gateway.currentstep];
						if (ide === undefined){
							Gateway.currentstep = 0;
							ide=Gateway.steps[Gateway.currentstep];
						}
					}else{
						Gateway.currentstep = step;
						ide=Gateway.steps[Gateway.currentstep];
					}
					
					scope.onProxyMessage( {command: 'goto', param: ide} );
					
			   }
			   
           }
       }
   }]);
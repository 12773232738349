angular.module('BGEHeroes.controllers.Black', [ ])

.controller('blackCtrl', ['$scope', '$log', '$location', 'Gateway', '$rootScope', '$window', '$interval',
	function($scope, $log, $location, Gateway, $rootScope, $window, $interval){

		$rootScope.loading = true;
	
		
		$scope.messageCall = function ( command, param ) {
			
			if (command == 'goto'){
				$rootScope.loading = true;
				$location.url(param);
				$location.replace();
				if (!$rootScope.$$phase) $rootScope.$apply();
			}
			
			if (command == 'ready'){
				$rootScope.loading = false;
				if (!$rootScope.$$phase) $rootScope.$apply();
			}

			console.log('Command: ' + command + ' Message: ' + param );
			
		}

		$scope.closeandjump = function (step){
			
			$rootScope.loading = true;
			
			var ide;
			
			if (step === undefined){
				ide=Gateway.steps[++Gateway.currentstep];
				if (ide === undefined){
					Gateway.currentstep = 0;
					ide=Gateway.steps[Gateway.currentstep];
				}
			}else{
				Gateway.currentstep = step;
				ide=Gateway.steps[Gateway.currentstep];
			}
			
			$location.url(ide);
			$location.replace();
			if (!$rootScope.$$phase) $rootScope.$apply();
			
	   }
		
		$log.log ("Black");

		$scope.idozites = function (evt){
			 $interval.cancel($scope.stopTime);
			 $scope.closeandjump();
		}

		$scope.stopTime = $interval($scope.idozites, 1000);
		
		
		
	}
]);
angular.module('BGEHeroes.directives.Lepes5', [])

.directive('lepes5', ['loaderSvcLepes5', '$location', 'Gateway', '$rootScope', function (loaderSvcLepes5, $location, Gateway, $rootScope) {
       "use strict";
       return {
           restrict : 'EAC',
           replace : true,
           scope :{
			    onProxyMessage: '&'
           },
           template: "<canvas></canvas>",
           link: function (scope, element, attribute) {
			   
               var w, h, lepes;
			   
			   var hatter1, hatter2, bar, logo, tovabb, ujra, kihagy;
			   
			   var keret1, keret2, keret3, keret4, kivalasztott1, kivalasztott2, felgomb, legomb, lift1, lift2, lift3, lift4, kijelzo1, kijelzo2, kijelzo3, kijelzo4, lifts1, lifts2, lifts3, lifts4, liftsm1, liftsm2, liftsm3, liftsm4;
			   
			   var STAGE_WIDTH, STAGE_HEIGHT, STAGE_ASPECT_RATIO, scope;
			   
			   var visszaallitas;
			   var kattintva;
			   
			   var ablak_nagy, ablakszoveg, tippszoveg;
							
			   var palyaszam, pontszam;
			   
			   var indulopontszam;
			   var eletekszama;

			   var myrate = Gateway.framerate;
			   
			   var hozzavalok = {};
			   
			   var tipp;

			   var kihagypos = [1920-250, 1080-140];
			   var tipppos = [1920-250-216-50, 1080-140];
			   var ujrapos = [1920-250-216-50, 1080-140];
			   var tovabbpos = [1920-250, 1080-140];
			   
			   var uzenet;		   
			   var lock;
			   var megoldva;
			   
			   var szovegek = {
					tipp: "Nem igényel előismeretet, fel kell mérni a lehetőségeket. Ha mindegyiket addig növeljük 7 esével, amíg 11 gyel osztható számot kapunk, akkor is eljutunk a megoldáshoz már csak párba kell rakni a mozdítandó lifteket. Emiatt azonban előre át kell gondolni a lépéseket.",
					kivalaszt: "Válassz ki pontosan 2 liftet, majd kattint a fel vagy le gombra!",
					start: "Szabadítsd ki a liftben rekedt vendégeket, ha liftek jelenleg az 5., 8., 4., illetve az 1. emeleten állnak.",
					kesz: "Gratulálunk, a feladat megoldása sikeres volt!",
					nem: "Sajnos a feladat megoldása sikertelen volt!"
			   };
			   
				lift1=5;
				lift2=8;
				lift3=4;
				lift4=1;
				
				kivalasztott1=0;
				kivalasztott2=0;

				 var startx = 300;
			     var szelesseg = 248;
				 var incx = 300;
				 var starty = 300;
					 
				var hotspots = [

					["fel",  startx+incx*4+25, starty+112,  48, 48, false],			
					["le",  startx+incx*4+25, starty+118+48, 48, 48, false]					
					];
				
				var vege = 0;
				
               drawGame();
			   
               function drawGame() {
                   if (scope.stage) {
                       scope.stage.autoClear = true;
                       scope.stage.removeAllChildren();
                       scope.stage.update();
                   } else {
                       scope.stage = new createjs.Stage(element[0]);
                   }
                   w = scope.stage.canvas.width = STAGE_WIDTH = 1920;
                   h = scope.stage.canvas.height = STAGE_HEIGHT = 1080;
				   
					loaderSvcLepes5.getLoader().addEventListener("complete", handleComplete);
					loaderSvcLepes5.loadAssets();

					/////////////////////////////////////////
					STAGE_ASPECT_RATIO = STAGE_WIDTH / STAGE_HEIGHT;
					var containerWidth = window.innerWidth;
					var containerHeight = window.innerHeight;
					var brratio = containerWidth/containerHeight;
					if (STAGE_ASPECT_RATIO>brratio){
						//A Stage magasabb. Teljes szélesség, a magasságot kell korlátozni.
						scope.stage.canvas.width = containerWidth;
						scope.stage.canvas.height = containerWidth / ref;
						   
					}else{
						//A Stage szélesebb. Teljes magasság, a szélességet kell korlátozni.
						scope.stage.canvas.width = containerHeight * ref;
						scope.stage.canvas.height = containerHeight;
					}
					// scale stage to fit canvas
					var scalex = scope.stage.canvas.width / STAGE_WIDTH;
					var scaley = scope.stage.canvas.height / STAGE_HEIGHT;
					scope.stage.scaleX = scalex;
					scope.stage.scaleY = scaley;
					scope.stage.update();					
					/////////////////////////////////////////////
				   
               }

			   function addhotspot ( i ){
			
					var graphics = new createjs.Graphics().beginStroke("#ff00ff").drawRect( hotspots[i][1], hotspots[i][2], hotspots[i][3], hotspots[i][4]);
					var grhit = new createjs.Graphics().beginFill("#000000").drawRect( hotspots[i][1], hotspots[i][2], hotspots[i][3], hotspots[i][4]);						
					var shape = new createjs.Shape(graphics);
					var hit = new createjs.Shape(grhit);
					shape.hitArea = hit;
					if ( !Gateway.devmode ){
						shape.alpha=0;
					}else{
						shape.alpha=0.5;
					}
					scope.stage.addChild(shape);
					shape.name = hotspots[i][0];
					shape.addEventListener("mousedown", kattintas);
					
					return shape;

			   }
			   
			   
			   
               function handleComplete() {
				   	
					lock = false;
					
					Gateway.pontkezdo = Gateway.pont;
					storagesave();
					
				   	//Gateway.pont += 100;
					//Gateway.palya += 1;					

					visszaallitas = 0;
				   
					hatter1 = addtostage ("hatter1", 0, 0, w, h);
					
					tippszoveg = addtext( w/2, h/2-100, Gateway.kezdoszovegek[Gateway.currentstep] + "\n\n" + szovegek["tipp"], "50px RobotoRegular", "#000", 1500, "center", 1);				
					tippszoveg.alpha = 0;
					
					ablak_nagy = addsprite("ablak_nagy", 0, 0, w, h, true );
					ablakszoveg = addtext( w/2, h/2-100, Gateway.kezdoszovegek[Gateway.currentstep], "70px RobotoRegular", "#000", 1500, "center", 1);				
					
					scope.stage.update();				
					ablakszoveg.y = h/2-ablakszoveg.getBounds().height/2;
					scope.stage.update();
					
					uzenet = addtext( +340, h-100, szovegek["start"], "40px RobotoRegular", "#000", 1050, "left", 1);
					uzenet.alpha = 0;
					
					logo = addsprite ("logo", 0, 50);
					logo.addEventListener("mousedown", fomenube);
					
					bar = addsprite ("bar", w-50-577, 50);

					createjs.Ticker.timingMode = createjs.Ticker.TIMEOUT;
					createjs.Ticker.framerate = myrate;
					createjs.Ticker.addEventListener("tick", tick);
				    createjs.Ticker.addEventListener("tick", createjs.Tween);
				   
					scope.onProxyMessage( {command: 'ready', param: ''} );
					
					updatepalyaszam();
					updatepontszam();

					scope.stage.update();
										
				   	createjs.Touch.enable(scope.stage);
					scope.stage.enableMouseOver(10);
					scope.stage.mouseMoveOutside = true;
					
					scope.stage.addEventListener("stagemousedown", handleComplete2);
					
			   }


               function handleComplete2(event) {
				   
				   console.log (event);
				   
				   event.stopImmediatePropagation();
				    event.stopPropagation();
				   
					kihagy = addsprite ( "kihagy", kihagypos[0], kihagypos[1] );
					kihagy.addEventListener("mousedown", kattintas);
					kihagy.alpha = 1;
				   
				    if (Gateway.isios){
					   Gateway.audio.powerup.play();
				   }else{
						createjs.Sound.play("powerup");
				   }
				   				   
					scope.stage.removeEventListener("stagemousedown", handleComplete2);
				   	ablak_nagy.alpha=0;
					ablakszoveg.alpha=0;
					
					//Gomb rajzolása
					ujra = addsprite ( "ujra", ujrapos[0], ujrapos[1] );
					ujra.addEventListener("mousedown", kattintas);
					ujra.alpha = 0;

					tovabb = addsprite ( "tovabb", tovabbpos[0], tovabbpos[1] );
					tovabb.addEventListener("mousedown", kattintas);
					tovabb.alpha = 0;


					tipp = addsprite ( "tipp", tipppos[0], tipppos[1] );
					tipp.addEventListener("mousedown", kattintas);
					tipp.alpha = 1;

					szovegkijelzes ("start");
					scope.stage.update();

					lifts1 = addsprite ( "lift", startx, starty );
					liftsm1 = addsprite ( "liftsmiley", startx, starty );
					liftsm1.alpha = 0;
					lifts1.name="1";
					lifts1.addEventListener("mousedown", kivalaszt);
					keret1 =  addsprite ( "keret", startx, starty );
					keret1.alpha = 0;

					lifts2 = addsprite ( "lift", startx+incx, starty );
					liftsm2 = addsprite ( "liftsmiley", startx+incx, starty );
					liftsm2.alpha = 0;
					lifts2.name="2";
					lifts2.addEventListener("mousedown", kivalaszt);
					keret2 =  addsprite ( "keret", startx+incx, starty );
					keret2.alpha = 0;

					lifts3=addsprite ( "lift", startx+incx*2, starty );
					liftsm3 = addsprite ( "liftsmiley", startx+incx*2, starty );
					liftsm3.alpha = 0;
					lifts3.name="3";
					lifts3.addEventListener("mousedown", kivalaszt);
					keret3 =  addsprite ( "keret", startx+incx*2, starty );
					keret3.alpha = 0;

					lifts4=addsprite ( "lift", startx+incx*3, starty );
					liftsm4 = addsprite ( "liftsmiley", startx+incx*3, starty );
					liftsm4.alpha = 0;
					lifts4.name="4";
					lifts4.addEventListener("mousedown", kivalaszt);
					keret4 =  addsprite ( "keret", startx+incx*3, starty );
					keret4.addEventListener("mousedown", kivalaszt);
					keret4.alpha = 0;

					addsprite ( "gombok", startx+incx*4, starty );

					//Hotspotok rajzolása					
					var i;
					for (i = 0; i < hotspots.length; i++) { 
						addhotspot(i);
					}	

					megrajzol();
					
			   }
			   
			   function kiertekel(){
				   
			   }
			   
			   function kivalaszt(event){
				   
				   console.log (event.target.name);
				   var mit = event.target.name;
				   //var mitsorszam = substring
				   
				   if (kivalasztott1 == mit){
					   //Volt ilyen
					   eval("keret"+mit).alpha=0;
					   kivalasztott1 = 0;
					   return;
				   }

				   if (kivalasztott2 == mit){
					   //Volt ilyen
					   eval("keret"+mit).alpha=0;
					   kivalasztott2 = 0;
					   return;
				   }				   
				   
				   //Mindkettő foglalt
					if (kivalasztott1 && kivalasztott2){
										
						eval("keret"+kivalasztott1).alpha=0;
						kivalasztott1=kivalasztott2;						
	
						eval("keret"+mit).alpha=1;
						kivalasztott2 = mit;
						return;
					}

				   //Egyik foglalt
					if (kivalasztott1 && !kivalasztott2){
						eval("keret"+mit).alpha=1;
						kivalasztott2 = mit;
						return;
					}
					if (!kivalasztott1){
						eval("keret"+mit).alpha=1;
						kivalasztott1 = mit;
						return;
					}				   
				   
				   
			   }
			   
			   function megrajzol (){
				   
				   
					if (kijelzo1) scope.stage.removeChild(kijelzo1);
					kijelzo1 = addtext( startx+szelesseg/2, starty+18, !lift1 ? "F" : lift1 , "35px RobotoBold", "#000", szelesseg, "center", true);

					if (kijelzo2) scope.stage.removeChild(kijelzo2);
					kijelzo2 = addtext( startx+szelesseg/2+incx, starty+18, !lift2 ? "F" : lift2 , "35px RobotoBold", "#000", szelesseg, "center", true);

					if (kijelzo3) scope.stage.removeChild(kijelzo3);
					kijelzo3 = addtext( startx+szelesseg/2+2*incx, starty+18, !lift3 ? "F" : lift3 , "35px RobotoBold", "#000", szelesseg, "center", true);

					if (kijelzo4) scope.stage.removeChild(kijelzo4);
					kijelzo4 = addtext( startx+szelesseg/2+3*incx, starty+18, !lift4 ? "F" : lift4 , "35px RobotoBold", "#000", szelesseg, "center", true);


					if (lift1 == 0 && lift2 == 0 && lift3 == 0 && lift4 == 0){
						vege = 1;
						tipp.alpha = 0;
						kihagy.alpha=0;
						tovabb.alpha = 1;
						ujra.alpha = 1;
						szovegkijelzes("kesz");
						
						liftsm1.alpha = 1;
						liftsm2.alpha = 1;
						liftsm3.alpha = 1;
						liftsm4.alpha = 1;
					}

				   
			   }
			   
			   function sugotmutat (){
				   
				    scope.stage.setChildIndex( ablak_nagy, scope.stage.getNumChildren()-1);
				    ablak_nagy.alpha=1;
				   
				    scope.stage.setChildIndex( tippszoveg, scope.stage.getNumChildren()-1);
				   
				   
					tippszoveg.y = h/2-tippszoveg.getBounds().height/2;
					scope.stage.update();
					
					tippszoveg.alpha=1;
				    scope.stage.addEventListener("stagemousedown", sugokikapcs);
				   
			   }
			   
			   function sugokikapcs (){
				    scope.stage.removeEventListener("stagemousedown", sugokikapcs);
				   	ablak_nagy.alpha=0;
					tippszoveg.alpha=0;
					
					szovegkijelzes ("start");
					
			   }
			   
			   function addtostage (id, x, y, w, h){
					var shape;
					shape = new createjs.Shape();
					shape.graphics.beginBitmapFill(loaderSvcLepes5.getResult(id)).drawRect(x, y, w, h);
					scope.stage.addChild(shape);
					return shape;
			   }
			   
			   function addsprite (id, x, y){
				   var bitmap;
				   bitmap = new createjs.Bitmap(loaderSvcLepes5.getResult(id));
				   bitmap.x = x;
				   bitmap.y = y;
				   scope.stage.addChild(bitmap);
				   return bitmap;
			   }
			   
			   	function storagesave(){
				   
				   	var storage = window.localStorage;

					storage.setItem("currentstep", Gateway.currentstep );
					storage.setItem("palya", Gateway.palya);
					storage.setItem("pont", Gateway.pont);
					storage.setItem("pontkezdo", Gateway.pontkezdo );
				   
			   }
			   
			   function fomenube(){
				   
				   	$rootScope.loading = true;					
			   		createjs.Ticker.reset();
					scope.stage.removeAllChildren();
					scope.stage.removeAllEventListeners();
					loaderSvcLepes5.getLoader().removeEventListener("complete", handleComplete);
				   
				   scope.onProxyMessage( {command: 'goto', param: "/fomenu"} );
			   }
			   
			   
			   function szovegkijelzes (id){
				 
				    visszaallitas = Gateway.szovegvisszaallas;
					uzenet.text = szovegek[id];
				    uzenet.alpha = 1;
			   }
			   

			   function addtext (xpos, ypos, text, font, mycolor, mywidth, myalign, myalpha ){
				   var myshape;

					var offsetx = 0;
					var offsety = 0;
				
				   if (xpos<0) xpos=w+xpos;
				   if (ypos<0) ypos=h+ypos;
				   
				   myshape = new createjs.Text(text, font, mycolor);
				   myshape.x = xpos + offsetx;
				   myshape.y = ypos + offsety ;
				   myshape.name = text;
				   
				   scope.stage.addChild(myshape);

				   myshape.lineWidth=mywidth;
				   myshape.textAlign=myalign;
				   myshape.alpha=myalpha;
				   
				   return myshape;
			   }
			   
			    function addsprite (id, x, y){
				   var bitmap;
				   bitmap = new createjs.Bitmap(loaderSvcLepes5.getResult(id));
				   bitmap.name = id;
				   bitmap.x = x;
				   bitmap.y = y;
				   scope.stage.addChild(bitmap);
				   return bitmap;
			   }			   
			   
				function updatepalyaszam (){
					if (palyaszam) scope.stage.removeChild(palyaszam);
					palyaszam = addtext( w-50-420, 75+Gateway.fontdiff, Gateway.palya + "." , "35px RobotoBold", "#FFFFFF", 100, "left", true);
				}

				function updatepontszam (){
					if (pontszam) scope.stage.removeChild(pontszam);
					pontszam = addtext( w-50-110, 75+Gateway.fontdiff, Gateway.pont , "35px RobotoBold", "#FFFFFF", 100, "left", true);
				}
				

			   
			   function tick(event) {

					if (!lock){
						if (visszaallitas>0){
							visszaallitas--;
						}else{
							uzenet.alpha = 0;
						}
					}
					
                   scope.stage.update(event);
               }

				function ujrarajzol(){
					
					console.log ("Újrarajzolás");
					
					lift1=5;
					lift2=8;
					lift3=4;
					lift4=1;
					
					kivalasztott1=0;
					kivalasztott2=0;

						liftsm1.alpha = 0;
						liftsm2.alpha = 0;
						liftsm3.alpha = 0;
						liftsm4.alpha = 0;


					vege = 0;
					tipp.alpha = 1;
					kihagy.alpha=1;
					tovabb.alpha = 0;
					ujra.alpha = 0;
					szovegkijelzes("start");
					
					megrajzol ();
				
				}
				
				function reset(){
					
					ujrarajzol();
					
				}
				

			   function kattintas (event){
				   

					var nev = event.target.name;


					if (nev == "fel"){
						
						if (kivalasztott1 == 0 || kivalasztott2==0){
							szovegkijelzes ("kivalaszt");
						}
					
						eval(" if(lift"+kivalasztott1+"+7<=100) lift" + kivalasztott1 + " += 7");
						eval(" if(lift"+kivalasztott2+"+7<=100) lift" + kivalasztott2 + " += 7");
					
						megrajzol ();
					
						console.log ("fel");
						return;
						
					}


					if (nev == "le"){

						if (kivalasztott1 == 0 || kivalasztott2==0){
							szovegkijelzes ("kivalaszt");
						}

						eval(" if(lift"+kivalasztott1+"-11>=0) lift" + kivalasztott1 + " -= 11");
						eval(" if(lift"+kivalasztott2+"-11>=0) lift" + kivalasztott2 + " -= 11");
					
						megrajzol ();
						
						console.log ("le");
						return;
						
					}


					if (nev == "ujra"){
						reset();
						return;
					}

					if (nev == "kihagy"){
						//Gateway.pont += 100;
						Gateway.palya += 1;					
						storagesave();
						
						if (Gateway.isios){
							Gateway.audio.drainmagic.play();
						}else{
							createjs.Sound.play("drainmagic");
						}
						closeandjump();
						return;
					}

					if (nev == "tovabb"){
						
						Gateway.pont += 100;
						Gateway.palya += 1;					
						storagesave();
						
						if (Gateway.isios){
							Gateway.audio.drainmagic.play();
						}else{
							createjs.Sound.play("drainmagic");
						}
						closeandjump();
						return;
					}

					if (nev == "tipp"){
						sugotmutat();
						return;
					}
					
					if (lock)return
					
				   if (Gateway.isios){
					   Gateway.audio.swish.play();
				   }else{
					   createjs.Sound.play("swish");
				   }


					
			   }
			   
			   function closeandjump(step){
					
					$rootScope.loading = true;					
			   		createjs.Ticker.reset();
					scope.stage.removeAllChildren();
					scope.stage.removeAllEventListeners();
					loaderSvcLepes5.getLoader().removeEventListener("complete", handleComplete);
					
					var ide;
					
					if (step === undefined){
						ide=Gateway.steps[++Gateway.currentstep];
						if (ide === undefined){
							Gateway.currentstep = 0;
							ide=Gateway.steps[Gateway.currentstep];
						}
					}else{
						Gateway.currentstep = step;
						ide=Gateway.steps[Gateway.currentstep];
					}
					
					scope.onProxyMessage( {command: 'goto', param: ide} );
					
			   }
			   
           }
       }
   }]);
angular.module('BGEHeroes.directives.Quiz5', [])

.directive('quiz5', ['loaderSvcQuiz5', '$location', 'Gateway', '$rootScope', function (loaderSvcQuiz5, $location, Gateway, $rootScope) {
       "use strict";
       return {
           restrict : 'EAC',
           replace : true,
           scope :{
			    onProxyMessage: '&'
           },
           template: "<canvas></canvas>",
           link: function (scope, element, attribute) {
			   
               var w, h, lepes;
			   var hatter, ablak;
			   var bar, logo;
			   var STAGE_WIDTH, STAGE_HEIGHT, STAGE_ASPECT_RATIO, scope;
			   var visszaallitas;
			   var kattintva;
			   var quizpointer;
			   var cim, kerdes, felirat;
			   var answers = [];
			   var qbs = [];

			   var palyaszam, pontszam;
			   
				var ablak_nagy, ablakszoveg, ujra, tovabb, barures, eletekszama, kihagy, kep, valasz;
			    var sikerkezdo = 5;
				var utkozeskezdo = 1;
				var siker;
				var utkozes;
				var myrate = Gateway.framerate;
				var indulopontszam;
				var alive;
				
			    var kihagypos = [1920-250, 1080-160];
						   
   			   lepes=0;

               drawGame();
			   

				var quizbuttons = [
									["sor1", 174, 370, 100, 100, false],
									["sor2", 174, 520, 100, 100, false],
									["sor3", 174, 670, 100, 100, false],
									["sor4", 174, 820, 100, 100, false]
									];	   
			   			   
				var quiz = [
					[	"Az egyik liftben két matematikus ragadt bent köztük az alábbi párbeszéd zajlott le:",
						["63", "72", "81", "90"],
						3
					]
				];
						   
               function drawGame() {
                   //drawing the game canvas from scratch here
                   //In future we can pass stages as param and load indexes from arrays of background elements etc
                   if (scope.stage) {
                       scope.stage.autoClear = true;
                       scope.stage.removeAllChildren();
                       scope.stage.update();
                   } else {
                       scope.stage = new createjs.Stage(element[0]);
                   }
                   w = scope.stage.canvas.width = STAGE_WIDTH = 1920;
                   h = scope.stage.canvas.height = STAGE_HEIGHT = 1080;
				   
					loaderSvcQuiz5.getLoader().addEventListener("complete", handleComplete);
					loaderSvcQuiz5.loadAssets();
				   
					/////////////////////////////////////////
					STAGE_ASPECT_RATIO = STAGE_WIDTH / STAGE_HEIGHT;
					var containerWidth = window.innerWidth;
					var containerHeight = window.innerHeight;
					var brratio = containerWidth/containerHeight;
					if (STAGE_ASPECT_RATIO>brratio){
						//A Stage magasabb. Teljes szélesség, a magasságot kell korlátozni.
						scope.stage.canvas.width = containerWidth;
						scope.stage.canvas.height = containerWidth / ref;
						   
					}else{
						//A Stage szélesebb. Teljes magasság, a szélességet kell korlátozni.
						scope.stage.canvas.width = containerHeight * ref;
						scope.stage.canvas.height = containerHeight;
					}
					// scale stage to fit canvas
					var scalex = scope.stage.canvas.width / STAGE_WIDTH;
					var scaley = scope.stage.canvas.height / STAGE_HEIGHT;
					scope.stage.scaleX = scalex;
					scope.stage.scaleY = scaley;
					scope.stage.update();					
					/////////////////////////////////////////////
				   
               }

			   function addtostage (id, x, y, w, h){
					var shape;
					shape = new createjs.Shape();
					shape.graphics.beginBitmapFill(loaderSvcQuiz5.getResult(id)).drawRect(x, y, w, h);
					scope.stage.addChild(shape);
					return shape;
			   }
			   
			   function addsprite (id, x, y){
				   var bitmap;
				   bitmap = new createjs.Bitmap(loaderSvcQuiz5.getResult(id));
				   bitmap.name = id;
				   bitmap.x = x;
				   bitmap.y = y;
				   scope.stage.addChild(bitmap);
				   return bitmap;
			   }
			   
			   function addhotspot ( i ){
			
					var graphics = new createjs.Graphics().beginStroke("#ff00ff").drawRect( hotspots[i][1], hotspots[i][2], hotspots[i][3], hotspots[i][4]);
					var grhit = new createjs.Graphics().beginFill("#000000").drawRect( hotspots[i][1], hotspots[i][2], hotspots[i][3], hotspots[i][4]);						
					var shape = new createjs.Shape(graphics);
					var hit = new createjs.Shape(grhit);
					shape.hitArea = hit;
					if ( !Gateway.devmode ){
						shape.alpha=0;
					}else{
						shape.alpha=0.5;
					}
					scope.stage.addChild(shape);
					shape.name = hotspots[i][0];
					shape.addEventListener("click", kattintas);
					
					return shape;

			   }
			   
			   function addtext (xpos, ypos, text, font, color){
				   var myshape;
			   
				   myshape = new createjs.Text(text, font, color);
				   myshape.x = xpos;
				   myshape.y = ypos;
				   
				   scope.stage.addChild(myshape);
				   
				   return myshape;
			   }
			   	function storagesave(){
				   
				   	var storage = window.localStorage;

					storage.setItem("currentstep", Gateway.currentstep );
					storage.setItem("palya", Gateway.palya);
					storage.setItem("pont", Gateway.pont);
					storage.setItem("pontkezdo", Gateway.pontkezdo );
				   
			   }			   
			   function handleComplete() {
				   
				    Gateway.pontkezdo = Gateway.pont;
				    storagesave();
				   	//Gateway.pont += 100;
					//Gateway.palya += 1;	

					indulopontszam = Gateway.pont;
					
					//MOD
					hatter = addtostage ("hatter", 0, 0, w, h);
					
					ablak_nagy = addsprite("ablak", 0, 0, w, h, true );
					ablakszoveg = addtext( w/2, h/2-100, Gateway.kezdoszovegek[Gateway.currentstep], "70px RobotoRegular", "#000", true);
					
				    ablakszoveg.lineWidth=1500;
				    ablakszoveg.textAlign="center";
					
					logo = addsprite ("logo", 0, 50);
					bar = addsprite ("bar", w-50-577, 50);
					
					logo.addEventListener("mousedown", fomenube);
					
				   	updatepalyaszam();
					updatepontszam();
					
					scope.onProxyMessage( {command: 'ready', param: ''} );
				   
					scope.stage.canvas.onclick = handleComplete2;
					scope.stage.update();

					ablakszoveg.y = h/2-ablakszoveg.getBounds().height/2;
					scope.stage.update();
					
				}

			   function fomenube(){
				   
				   	$rootScope.loading = true;					
			   		createjs.Ticker.reset();
					scope.stage.removeAllChildren();
					scope.stage.removeAllEventListeners();
					loaderSvcQuiz5.getLoader().removeEventListener("complete", handleComplete);
				   
				   scope.onProxyMessage( {command: 'goto', param: "/fomenu"} );
			   }				
				
               function handleComplete2() {

					scope.stage.canvas.onclick = null;
						if (Gateway.isios){
						   Gateway.audio.powerup.play();
					   }else{
						   createjs.Sound.play("powerup");
					   }	
			   
					createjs.Touch.enable(scope.stage);
					scope.stage.enableMouseOver(Gateway.framerate);
					scope.stage.mouseMoveOutside = true;

					scope.stage.removeAllChildren();
					scope.stage.removeAllEventListeners();
			   
					Gateway.pont = indulopontszam;
			   
				   visszaallitas = 0;
				   quizpointer=-1;
				   
				   siker=sikerkezdo;
				   utkozes=utkozeskezdo;
				   
				   //Lépés1, minden kirajzolása			   
					hatter = addtostage ("hatter", 0, 0, w, h);
				   ablak = addtostage ("ablak", 0, 0, w, h);

				   	kihagy = addsprite ( "kihagy", kihagypos[0], kihagypos[1] );
					kihagy.addEventListener("mousedown", kattintas);
					kihagy.alpha = 1;
				   	
					kep = addsprite ( "kep", w-1000, h/2-220 );
					kep.alpha = 1;

					logo = addsprite ("logo", 0, 50);
					bar = addsprite ("bar", w-50-577, 50);
					//barures = addsprite ("barures", w-50-577, 130);
					
					logo.addEventListener("mousedown", fomenube);
					
				   	if (!createjs.Ticker.hasEventListener("tick")) {
						createjs.Ticker.timingMode = createjs.Ticker.TIMEOUT;
						createjs.Ticker.framerate = myrate;
						createjs.Ticker.addEventListener("tick", tick);
					}

					nextquiz();
				   
   				   scope.onProxyMessage( {command: 'ready', param: ''} );
				   
				   	updatepalyaszam();
					updatepontszam();
					//updateeletekszama();
					
					alive=1;

			   }

			   function vege() {

					scope.stage.removeAllChildren();
					scope.stage.removeAllEventListeners();
					
					ablak_nagy = addsprite("ablak", 0, 0, w, h, true );				
					if (utkozes==0){
						ablakszoveg = addtext( w/2, h-800, Gateway.jatekvegesikertelen, "120px RobotoRegular", "#000", true);
					}else if (utkozes==utkozeskezdo){
						ablakszoveg = addtext( w/2, h-900, "Gratulálunk, helyes a megoldás.", "60px RobotoRegular", "#000", true);
					}else{
						ablakszoveg = addtext( w/2, h-900, Gateway.jatekvegejo, "120px RobotoRegular", "#000", true);
					}

				    ablakszoveg.lineWidth=1500;
				    ablakszoveg.textAlign="center";

					logo = addsprite ("logo", 0, 50);
					bar = addsprite ("bar", w-50-577, 50);
					
					logo.addEventListener("mousedown", fomenube);
					
					updatepalyaszam();
					updatepontszam();
					
					scope.stage.update(); 	//update the stage to show text
					//ablakszoveg.y = h/2-ablakszoveg.getBounds().height/2-100;
					//scope.stage.update();
					
	
					if ( utkozes<utkozeskezdo){
						ujra = addsprite ("ujra", w/3-100, h-300);
						ujra.addEventListener("mousedown", handleComplete2);
					}
					
					if ( utkozes!=0 || Gateway.jatekdev){
						
						valasz = addsprite ( "valasz", w/2-620, h-800 );
						valasz.alpha = 1;
						
						tovabb = addsprite ("tovabb", w-400, h-300);
						tovabb.addEventListener("mousedown", kovetkezolap);
					}
				}
			   
				function updatepalyaszam (){
					if (palyaszam) scope.stage.removeChild(palyaszam);
					palyaszam = addtext( w-50-420, 75+Gateway.fontdiff, Gateway.palya + "." , "35px RobotoBold", "#FFFFFF");
				}

				function updatepontszam (){
					if (pontszam) scope.stage.removeChild(pontszam);
					pontszam = addtext( w-50-110, 75+Gateway.fontdiff, Gateway.pont , "35px RobotoBold", "#FFFFFF");
				}
				
			   
			   function addquizbutton ( i , szoveg){
		
					var bitmap;
					if (szoveg){
						bitmap = new createjs.Bitmap(loaderSvcQuiz5.getResult(szoveg));
					}else{
						bitmap = new createjs.Bitmap(loaderSvcQuiz5.getResult("quizalap"));
					}
					bitmap.x = quizbuttons[i][1];
					bitmap.y = quizbuttons[i][2];
					scope.stage.addChild(bitmap);
				   
					bitmap.name = quizbuttons[i][0];
					bitmap.addEventListener("mousedown", quizkattintas);
					
					return bitmap;

			   }
			   
				function updateeletekszama (){
					if (eletekszama) scope.stage.removeChild(eletekszama);
					eletekszama = addtext( w-615, 155+Gateway.fontdiff, "Akadályok: " + siker + "   Életek száma: " + utkozes , "35px RobotoBold", "#FFFFFF", true);
				}
				
			   function quizkattintas (event){

   				   if (visszaallitas) return;				   

				   siker--;
				   
					var i;
					for (i = 0; i < quizbuttons.length; i++) { 
						if (quizbuttons[i][0] == event.target.name ){
							var obj = quizbuttons[i];
							break;
						}				
					}
				   
				   scope.stage.removeChild(qbs[i]);
				   
					if (i == quiz[quizpointer][2]){
						//jó válasz
						qbs[i] = addquizbutton (i,"quizjo");
						Gateway.pont+=50;
						if (Gateway.isios){
						   Gateway.audio.uj_helyes.play();
					   }else{
						   createjs.Sound.play("uj_helyes");
					   }	
					}else{
						//rossz válasz
						qbs[i] = addquizbutton (i,"quizrossz");
						Gateway.pont-=10;
						utkozes--;
						if (Gateway.isios){
						   Gateway.audio.uj_rossz.play();
					   }else{
						   createjs.Sound.play("uj_rossz");
					   }	
					}

						updatepontszam();
						//updateeletekszama();
						
				    visszaallitas = Gateway.hotspotvisszaallas;
					//kattintva = event.target.name;
					
			   }
			   
				function nextquiz (){
					
					quizpointer++;

						if (utkozes==0 || siker==0){
							alive=0;
							vege();
							return;
						}
					
					if (quizpointer >= quiz.length){
						visszaallitas=0;
						vege();
						return;
					}else{
					
							if (quizpointer != 0){
								scope.stage.removeChild(cim);
								scope.stage.removeChild(kerdes);
								scope.stage.removeChild(answers[0]);
								scope.stage.removeChild(answers[1]);
								scope.stage.removeChild(answers[2]);
								scope.stage.removeChild(answers[3]);
								scope.stage.removeChild(qbs[0]);
								scope.stage.removeChild(qbs[1]);
								scope.stage.removeChild(qbs[2]);
								scope.stage.removeChild(qbs[3]);
							}
							
							//felirat = addtext( 154, 100, "KVÍZ", "80px RobotoBold", "#FFFFFF");
							//cim =     addtext( 154, 150, "KVÍZ - " + (quizpointer+1)+". kérdés", "80px RobotoRegular", "#FFFFFF");					
							kerdes =  addtext( 174, 200,  quiz[quizpointer][0] , "60px RobotoRegular", "#000");
							
							kerdes.lineWidth=1600;
							
							
							var i;
							for (i = 0; i < 4; i++) { 
								answers[i] = addtext( quizbuttons[i][1] + 150, quizbuttons[i][2] + 30,  quiz[quizpointer][1][i] , "60px RobotoRegular", "#000");
								qbs[i] = addquizbutton (i);
							}

					}
					
				}
			   
			   function tick(event) {

					if (visszaallitas == 1 && alive){
						//hatter2.alpha = 0;
						nextquiz();
					}
					if (visszaallitas>0) visszaallitas--;
                   scope.stage.update(event);
               }

			   function kattintas (event){
				   
				   if (event.target.name == "kihagy"){
					   closeandjump();
					   return;
				   }
				   
				   //if (visszaallitas && event.target.name == "doboz" && kattintva == "doboz") closeandjump();
				   
				   if (visszaallitas) return;				   
				   kattintva = event.target.name;
				   visszaallitas = Gateway.hotspotvisszaallas;
				   
					var i;
					for (i = 0; i < hotspots.length; i++) { 
						if (hotspots[i][0] == event.target.name ){
							var obj = hotspots[i];
							break;
						}				
					}
					
					var maskgr = new createjs.Graphics().beginFill("#000000").drawRect( obj[1], obj[2], obj[3], obj[4]);
					var m = new createjs.Shape(maskgr);
					
					hatter2.mask = m;
					hatter2.alpha = 1;
					
			   }
			   
               function stageinterakcio() {	   
			   
					if (lepes==0){
						
						return;
					}
			   
					if (lepes==1){
						return;
					}
			   
					closeandjump();
				   
               }

			   function kovetkezolap(step) {
					
					Gateway.pont += 100;
				   closeandjump();
			   }
			   
			    function closeandjump(step){
					
						Gateway.palya += 1;					
						storagesave();
					
						if (Gateway.isios){
						   Gateway.audio.powerup.play();
					   }else{
						   createjs.Sound.play("powerup");
					   }	
					
					$rootScope.loading = true;
					
					createjs.Ticker.removeEventListener("tick", tick);
			   		//createjs.Ticker.reset();
					
					scope.stage.removeAllChildren();
					scope.stage.removeAllEventListeners();
					loaderSvcQuiz5.getLoader().removeEventListener("complete", handleComplete);
					
					var ide;
					
					if (step === undefined){
						ide=Gateway.steps[++Gateway.currentstep];
						if (ide === undefined){
							Gateway.currentstep = 0;
							ide=Gateway.steps[Gateway.currentstep];
						}
					}else{
						Gateway.currentstep = step;
						ide=Gateway.steps[Gateway.currentstep];
					}
					
					scope.onProxyMessage( {command: 'goto', param: ide} );
					
			   }
           }
       }
   }]);
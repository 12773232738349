angular.module('BGEHeroes.services.LoaderSvcQuiz5', [])

.service('loaderSvcQuiz5', [ 'Gateway',  function (Gateway) {
   var manifest = [ 

       {src: "kozos/bg.png", id: "hatter"},
       {src: "kozos/ablak_nagy.png", id: "ablak"},
	   {src: "kozos/logo.png", id: "logo"},
       {src: "kozos/bar.png", id: "bar"},
       {src: "kozos/quiz_alap.png", id: "quizalap"},	   
       {src: "kozos/quiz_rossz.png", id: "quizrossz"},	   
       {src: "kozos/quiz_jo.png", id: "quizjo"},
	   {src: "kozos/bar_ures.png", id: "barures"},
	   {src: "kozos/tovabb.png", id: "tovabb"},
	   {src: "kozos/ujra.png", id: "ujra"},
	   
	   {src: "kozos/kihagy.png", id: "kihagy"},	   
	   {src: "quiz5/kep.png", id: "kep"},	  	   
	   {src: "quiz5/valasz.png", id: "valasz"},	  	  	   
   ];
   
   loader = new createjs.LoadQueue(false);
   
   this.getResult = function (asset) {
       return loader.getResult(asset);
   };
   this.getLoader = function () {
       return loader;
   };
   this.loadAssets = function () {  
		loader.loadManifest(manifest, true, Gateway.cordovaprefix + "/images/");
   };
}]);
